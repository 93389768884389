import React from "react"

class Payment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            docTypes: [],
            total: this.props.total
        }
    }

    async componentDidMount() {
        await this.getDocTypes()
    }

    getDocTypes = async () => {
        const docTypes = await window.mp.getIdentificationTypes();

        this.setState({
            docTypes: docTypes
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.props.onSubmit()
    }

    render() {
        return (
            <form className="dash-form-container" id="form-checkout">
                <h3 className="dash-form-title envio-title">
                    Pago
                </h3>
                <br />
                <span className="dash-form-space">
                    <span className="dash-form-column">
                        <input
                            type="text"
                            maxLength="16"
                            name="number"
                            id="form-checkout__cardNumber"
                            placeholder="Número de la tarjeta"
                            className="dash-form-input input-envio"
                        />

                        <input
                            type="text"
                            maxLength="7"
                            name="expiry"
                            id="form-checkout__cardExpirationDate"
                            placeholder="Fecha de vencimiento (MM/YYYY)"
                            className="dash-form-input input-envio"
                        />

                        <input
                            type="text"
                            name="name"
                            id="form-checkout__cardholderName"
                            placeholder="Titular de la tarjeta"
                            className="dash-form-input input-envio"
                        />

                        <input
                            className="dash-form-input input-envio"
                            type="text"
                            maxLength="5"
                            name="cvc"
                            id="form-checkout__securityCode"
                            placeholder="Código de seguridad"
                        />
                    </span>
                    <span className="dash-form-column">
                        <input
                            type="email"
                            name="cardholderEmail"
                            id="form-checkout__cardholderEmail"
                            placeholder="E-mail"
                            className="dash-form-input input-envio"
                        />
                        <select
                            style={{ "display": "none" }}
                            name="issuer"
                            id="form-checkout__issuer"
                            className="dash-form-input input-envio"
                        >
                        </select>

                        <select
                            name="identificationType"
                            id="form-checkout__identificationType"
                            className="dash-form-input input-envio"
                        >
                            {
                                this.state.docTypes.map((e) => {
                                    return (
                                        <option key={e.id}>
                                            {e.name}
                                        </option>
                                    )
                                })
                            }
                        </select>

                        <input
                            type="text"
                            name="identificationNumber"
                            id="form-checkout__identificationNumber"
                            placeholder="Número de documento"
                            className="dash-form-input input-envio"
                        />

                        <select
                            name="installments"
                            id="form-checkout__installments"
                            className="dash-form-input input-envio"
                        >

                        </select>
                    </span>
                </span>
                <button type="submit" id="form-checkout__submit" className="dash-form-button">Pagar</button>
            </form >
        )
    }
}

export default Payment