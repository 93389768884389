import React from "react";

import '../forms.css'

import { connect } from 'react-redux'
import { add_col, get_editoriales } from '../../../redux/actions'

import Swal from 'sweetalert2'

import Loader from '../../../components/loader/loader'
import { auth } from "../../../firebase";

class AddCol extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            editoriales: [],
            submitted: false
        }
    }

    componentDidMount() {
        this.setState({
            user: this.props.user
        }, () => {
            this.props.get_editoriales()
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user !== this.props.user) {
            this.setState({
                user: this.props.user
            })
        }

        if (prevProps.state.editoriales !== this.props.state.editoriales) {
            this.setState({
                editoriales: this.props.state.editoriales
            }, () => {
                this.setState({
                    loading: false
                })
            })
        }

        if (prevProps.state.status !== this.props.state.status) {
            document.getElementById('addcol-button').disabled = false
            if (this.props.state.status.code === 200) {
                return Swal.fire({
                    icon: 'success',
                    title: "Serie agregada con éxito!"
                })
                    .then(() => {
                        window.location.reload()
                    })
            } else {
                document.getElementById('addcomic-button').disabled = false
                return Swal.fire({
                    icon: 'error',
                    title: this.props.state.status.message
                })
            }
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        document.getElementById('addcol-button').disabled = true

        let nombre = document.getElementById("addcol-serie")
        let editorial = document.getElementById("addcol-editorial")
        let origen = document.getElementById("addcol-editorialor")

        const inputs = document.getElementsByClassName('dash-form-input')
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].style.borderColor = "transparent"
        }

        if (nombre.value.length === 0) {
            nombre.style.borderColor = "red"
            return Swal.fire({
                icon: 'error',
                title: 'Ingrese un nombre válido'
            })
        }

        if (editorial.value === "Editorial") {
            editorial.style.borderColor = "red"
            return Swal.fire({
                icon: 'error',
                title: 'Ingrese una editorial válida'
            })
        }

        if (origen.value.length === 0) {
            origen.style.borderColor = "red"
            return Swal.fire({
                icon: 'error',
                title: 'Ingrese una editorial de origen válida'
            })
        }

        const serie = {
            nombre: nombre.value,
            editorial: editorial.value,
            editorial_origen: origen.value,
            user_id: auth.currentUser?.uid || undefined
        }

        this.setState({
            submitted: true
        }, () => {
            this.props.add_col(serie)
            return Swal.fire({
                title: 'Agregando colección...',
                showConfirmButton: false,
                allowEnterKey: false,
                allowEscapeKey: false,
                allowOutsideClick: false
            })
        })

    }

    render() {
        if (this.state.loading) return <Loader />
        return (
            <form className="dash-form-container" onSubmit={this.handleSubmit}>
                <h3 className="dash-form-title">
                    Agregar colección
                </h3>
                <label htmlFor="addcol-serie" className="dash-form-label first-label">
                    Nombre
                </label>
                <input
                    className="dash-form-input input-nombre"
                    id="addcol-serie"
                    type="text"
                />
                <span className="dash-form-space">
                    <div className="dash-form-column">
                        <select
                            className="dash-form-input"
                            id="addcol-editorial"
                        >
                            <option key={`-addcol`} selected={true} disabled={true}>
                                Editorial
                            </option>
                            {
                                this.state.editoriales.map((e) => {
                                    return (
                                        <option key={e.id}>
                                            {e.name}
                                        </option>
                                    )
                                })
                            }
                        </select>
                        <label htmlFor="addcol-editorialor" className="dash-form-label">
                            Editorial de origen
                        </label>
                        <input
                            className="dash-form-input"
                            id="addcol-editorialor"
                            type="text"
                        />
                    </div>
                </span>

                <button className="dash-form-button" id="addcol-button" type="submit">
                    Crear
                </button>
            </form>
        )
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    add_col,
    get_editoriales
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(AddCol);