import React from "react";

import '../forms.css'

import { connect } from 'react-redux'
import { add_manga_vol, get_series_manga } from '../../../redux/actions'
import { control_inputs_vol, serie_exist } from './adminFunc'

import { auth, storage } from '../../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import Loader from '../../../components/loader/loader'

import Swal from 'sweetalert2'

class AddMangaVol extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            series: [],
            img: "https://firebasestorage.googleapis.com/v0/b/proyecto-comiqueria.appspot.com/o/covers%2Fno-cover.jpg?alt=media&token=badf49a6-465c-414a-8443-814e52583cab",
            submitted: false,
            loading: true,
            user: null
        }
    }

    componentDidMount() {
        this.setState({
            user: this.props.user
        }, () => {
            this.props.get_series_manga()
        })
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.user !== this.props.user) {
            this.setState({
                user: this.props.user
            })
        }
        
        if (prevProps.state.products !== this.props.state.products) {
            this.setState({
                series: this.props.state.products
            }, () => {
                this.setState({
                    loading: false
                })
            })
        }

        if (prevProps.state.status !== this.props.state.status && this.state.submitted) {
            if (this.props.state.status.code === 200) {
                return Swal.fire({
                    icon: 'success',
                    title: "Tomo agregado con éxito!"
                })
                    .then(() => {
                        window.location.reload()
                    })
            } else {
                document.getElementById('addmangavol-button').disabled = true
                return Swal.fire({
                    icon: 'error',
                    title: this.props.state.status.message
                })
            }
        }
    }

    handleUpload = (e) => {
        var file = e.target.files[0]
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            this.setState({
                img: reader.result
            })
        }
        reader.onerror = function (error) {
            console.log('Error: ', error)
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()

        let errors = control_inputs_vol()

        if (errors.code !== 200) {
            return Swal.fire({
                icon: "error",
                title: errors.text
            })
        } else {
            let vol = errors.vol

            let exist = serie_exist(vol, this.state.series)

            if (exist.code !== 200) {
                if (exist.exist === "tomo") {
                    return Swal.fire({
                        icon: "error",
                        title: `${vol.serie} vol ${vol.numero} ${vol.special} ya existe!`
                    })
                } else if (exist.exist === "serie") {
                    return Swal.fire({
                        icon: "error",
                        title: `La serie ${vol.serie} no existe!`
                    })
                }
            } else {
                vol.user_id = auth.currentUser?.uid

                const cover = document.getElementById('addmangavol-img').files[0]
                const storageRef = ref(storage, `/covers/mangas/${vol.serie}/${cover.name}`);
                const task = uploadBytesResumable(storageRef, cover);

                task.on(
                    "state_changed",
                    (snapshot) => {
                        console.log(snapshot)
                    },
                    (error) => {
                        console.log(error.message);
                    },
                    () => {
                        getDownloadURL(task.snapshot.ref).then((downloadURL) => {
                            this.setState({
                                submitted: true
                            }, () => {
                                document.getElementById('addmangavol-button').disabled = true
                                vol.imgURL = downloadURL
                                this.props.add_manga_vol(vol)
                                return Swal.fire({
                                    title: 'Subiendo tomo...',
                                    showConfirmButton: false,
                                    allowEnterKey: false,
                                    allowEscapeKey: false,
                                    allowOutsideClick: false
                                })
                            })
                        })
                    }
                )
            }
        }
    }

    render() {
        if (this.state.loading) return <Loader />
        return (
            <form className="dash-form-container" onSubmit={this.handleSubmit}>
                <h3 className="dash-form-title">
                    Agregar tomo
                </h3>
                <input list="addmanga-list-serie"
                    className="dash-form-input input-nombre"
                    placeholder="Serie"
                    id="addmangavol-serie"
                />
                <datalist
                    className="dash-form-input input-nombre"
                    id="addmanga-list-serie"
                >
                    {
                        this.state.series.map((s) => {
                            return (
                                <option key={`${s.id}-addmanga`}>
                                    {s.name}
                                </option>
                            )
                        })
                    }
                </datalist>
                <span className="dash-form-space">
                    <div className="dash-form-column">
                        <label htmlFor="addmangavol-numero" className="dash-form-label first-label">
                            Número
                        </label>
                        <input
                            className="dash-form-input"
                            id="addmangavol-numero"
                            type="number"
                            min={0}
                        />
                        <label htmlFor="addmangavol-stock" className="dash-form-label">
                            Stock
                        </label>
                        <input
                            className="dash-form-input"
                            id="addmangavol-stock"
                            type="number"
                            placeholder="0"
                            min={0}
                        />
                        <label htmlFor="addmangavol-precio" className="dash-form-label">
                            Precio
                        </label>
                        <input
                            className="dash-form-input"
                            id="addmangavol-precio"
                            type="number"
                            min={0}
                        />
                        <label htmlFor="addmangavol-special" className="dash-form-label">
                            Ed. especial
                        </label>
                        <input
                            className="dash-form-input"
                            id="addmangavol-special"
                            type="text"
                            placeholder="En blanco p/ ed. regulares"
                        />
                    </div>
                    <div className="dash-form-column">
                        <img className="dash-form-img"
                            id="addmangavol-img-src"
                            src={this.state.img}
                            alt="cover" />
                        <label htmlFor="addmangavol-img" className="dash-form-label cover">
                            Cover
                        </label>
                        <input
                            className="dash-form-input"
                            id="addmangavol-img"
                            type="file"
                            onChange={this.handleUpload}
                        />
                    </div>
                </span>
                <button className="dash-form-button" type="submit" id="addmangavol-button">
                    Crear
                </button>
            </form>
        )
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    add_manga_vol,
    get_series_manga
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(AddMangaVol);