import React from "react";

import { Link } from 'react-router-dom'
import './card.css'

class Card extends React.Component{
    render(){
        return(
            <Link to={`/serie/${this.props.vol.id}`} className="scard-main-container">
                <img className="scard-img" alt="cover" src={this.props.vol.img_url} />
                <section className="scard-info">
                    <p className="scard-title">
                        {this.props.vol.name}
                    </p>
                </section>
            </Link>
        )
    }
}

export default Card