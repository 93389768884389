import React from 'react'

import '../productsStyle.css'

import { connect } from 'react-redux'
import { get_figuras } from "../../redux/actions/index"

import Loader from '../../components/loader/loader'
import Card from '../../components/card/card'

import '../seriePage/seriePage.css'
import '../productsStyle.css'

class Figuras extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            figuras: []
        }
    }

    componentDidMount() {
        this.props.get_figuras()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.state.products !== this.props.state.products) {
            this.setState({
                figuras: this.props.state.products
            }, () => {
                this.setState({
                    loading: false
                })
            })
        }
    }

    render() {
        if (this.state.loading) return <Loader />
        return (
            <div className='products-main-container'>
                <section className="products-editoriales-title-container">
                    <h2 className="products-editoriales-title">
                        Figuras
                    </h2>
                </section>
                <section className="serie-volumes-grid">
                    {
                        this.state.figuras.map((v) => {
                            return <Card vol={v} key={v.id} />
                        })
                    }
                </section>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    get_figuras
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(Figuras);