import React from "react";

import '../forms.css'

import { connect } from 'react-redux'
import { add_libro, get_editoriales } from '../../../redux/actions'

import Swal from 'sweetalert2'

import Loader from '../../../components/loader/loader'
import { auth } from "../../../firebase";

class AddLibro extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            editoriales: [],
            loading: true,
            user: null
        }
    }

    componentDidMount() {
        this.setState({
            user: this.props.user
        }, () => {
            this.props.get_editoriales()
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user !== this.props.user) {
            this.setState({
                user: this.props.user
            })
        }

        if (prevProps.state.editoriales !== this.props.state.editoriales) {
            this.setState({
                editoriales: this.props.state.editoriales
            }, () => {
                this.setState({
                    loading: false
                })
            })
        }

        if (prevProps.state.status !== this.props.state.status) {
            if (this.props.state.status.code === 200) {
                return Swal.fire({
                    icon: 'success',
                    title: "Serie agregada con éxito!"
                })
                    .then(() => {
                        window.location.reload()
                    })
            }else{
                return Swal.fire({
                    icon: 'error',
                    title: this.props.state.status.message
                })
            }
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()

        let nombre = document.getElementById("addlibro-serie")
        let guion = document.getElementById("addlibro-guion")
        let vol_amount = document.getElementById("addlibro-cant")
        let state = document.getElementById("addlibro-state")
        let editorial = document.getElementById("addlibro-editorial")
        let sinopsis = document.getElementById("addlibro-sinopsis")

        const inputs = document.getElementsByClassName('dash-form-input')
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].style.borderColor = "transparent"
        }

        if (nombre.value.length === 0) {
            nombre.style.borderColor = "red"
            return Swal.fire({
                icon: 'error',
                title: 'Ingrese un nombre válido'
            })
        }

        if (guion.value.length === 0) {
            guion.style.borderColor = "red"
            return Swal.fire({
                icon: 'error',
                title: 'Ingrese un autor válido'
            })
        }

        if (vol_amount.value <= 0) {
            vol_amount.style.borderColor = "red"
            return Swal.fire({
                icon: 'error',
                title: 'Ingrese una cantidad de tomos válida'
            })
        }

        if (editorial.value === "Editorial") {
            editorial.style.borderColor = "red"
            return Swal.fire({
                icon: 'error',
                title: 'Ingrese una editorial válida'
            })
        }

        const serie = {
            nombre: nombre.value,
            autor: guion.value,
            vol_amount: vol_amount.value,
            editorial: editorial.value,
            sinopsis: sinopsis.value,
            state: state.value,
            user_id: auth.currentUser?.uid
        }

        document.getElementById('addlibro-button').disabled = true
        this.props.add_libro(serie)
        return Swal.fire({
            title: 'Agregando serie...',
            showConfirmButton: false,
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false
        })

    }

    render() {
        if (this.state.loading) return <Loader />
        return (
            <form className="dash-form-container" onSubmit={this.handleSubmit}>
                <h3 className="dash-form-title">
                    Agregar serie
                </h3>
                <label htmlFor="addlibro-serie" className="dash-form-label first-label">
                    Nombre
                </label>
                <input
                    className="dash-form-input input-nombre"
                    id="addlibro-serie"
                    type="text"
                />
                <span className="dash-form-space">
                    <div className="dash-form-column">
                        <label htmlFor="addlibro-guion" className="dash-form-label">
                            Autor
                        </label>
                        <input
                            className="dash-form-input"
                            id="addlibro-guion"
                            type="text"
                        />
                        <label htmlFor="addlibro-cant" className="dash-form-label">
                            Cant. tomos
                        </label>
                        <input
                            className="dash-form-input"
                            id="addlibro-cant"
                            type="number"
                            min={0}
                        />
                        <label htmlFor="addlibro-state" className="dash-form-label">
                            Estado
                        </label>
                        <select
                            className="dash-form-input"
                            id="addlibro-state"
                        >
                            <option>
                                Abierta
                            </option>
                            <option>
                                Cerrada
                            </option>
                        </select>
                    </div>
                    <div className="dash-form-column">
                        <select
                            className="dash-form-input"
                            id="addlibro-editorial"
                        >
                            <option key={`-addlibro`} selected={true} disabled={true}>
                                Editorial
                            </option>
                            {
                                this.state.editoriales.map((e) => {
                                    return (
                                        <option key={e.id}>
                                            {e.name}
                                        </option>
                                    )
                                })
                            }
                        </select>
                        <label htmlFor="addlibro-sinopsis" className="dash-form-label">
                            Sinopsis
                        </label>
                        <textarea
                            className="dash-form-input text-area"
                            id="addlibro-sinopsis"
                        />
                    </div>
                </span>

                <button className="dash-form-button" id="addlibro-button" type="submit">
                    Crear
                </button>
            </form>
        )
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    add_libro,
    get_editoriales
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(AddLibro);