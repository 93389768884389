import React from "react";

import "./footer.css"

import { BsInstagram, BsFillPinMapFill } from 'react-icons/bs'

class Footer extends React.Component{
    render(){
        return(
            <footer className="footer-main-container">
                <section className="footer-title-container">
                    <h3 className="footer-title">
                        The Bookhall
                    </h3>
                    <p className="footer-subtitle">
                        libros y cómics
                    </p>
                </section>
                <section className="footer-info-container">
                    <a href="https://goo.gl/maps/mixwiRvgYMBzxQU96"
                    target="_blank"
                    rel="noreferrer"
                    className="footer-info">
                        <BsFillPinMapFill className="footer-icon" />Av. Francisco Beiró 4404, Villa Devoto, Buenos Aires, Argentina
                    </a>
                    <p className="footer-info">
                        Lun - Sab, 11 a 19hs
                    </p>
                    <a href="https://www.instagram.com/thebookhall_lyc/"
                    target="_blank"
                    rel="noreferrer"
                    className="footer-info info-ig">
                        <BsInstagram className="footer-icon ig" />thebookhall_lyc
                    </a>
                </section>
                <section className="footer-copyrigth-container">
                    <p className="footer-copyrigth">
                        Bautista Manolizi, 2023
                    </p>
                </section>
            </footer>
        )
    }
}

export default Footer