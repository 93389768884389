import { auth } from '../../firebase'

export default function checkUser(obj) {
    obj.setState({
        loading: true
    }, () => {
        setTimeout(() => {
            let loading = false
    
            if (!obj.state.user)
                loading = true
            if (!auth.currentUser)
                loading = true
            if (auth.currentUser?.uid !== obj.state.user?.id)
                loading = true
            if (!obj.state.user?.admin)
                loading = true
     
            obj.setState({
                loading: loading
            })
        }, 3000);
    })
}