import React from "react";

import './home.css'

import Banners from './banners/banners'
import News from "./news/news"
import Bestsellers from "./bestsellers/bestsellers"

import { connect } from 'react-redux'
import { get_home_products } from "../../redux/actions/index"

import Loader from '../../components/loader/loader'

class Home extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            news: [],
            others: [],
            bs: []
        }
    }

    componentDidMount() {
        this.props.get_home_products()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.state.products !== this.props.state.products) {
            this.setState({
                news: this.props.state.products.news,
                bs: this.props.state.products.bestsellers
            }, () => {
                if(!this.state.news){
                    this.setState({
                        news: []
                    }, ()=>{
                        this.setState({
                            loading: false
                        })
                    })
                }else{
                    this.setState({
                        loading: false
                    })
                }
            })
        }
    }

    render() {
        if(this.state.loading) return <Loader />
        return (
            <div className="menu-main">
                <div className="banner-container">
                    <Banners />
                </div>
                <div className="news-container">
                    <div className="news-title-main">
                        <span className="news-bar izq" />
                        <span className="news-title-container">
                            <h1 className="news-title">
                                NOVEDADES
                            </h1>
                        </span>
                        <span className="news-bar der" />
                    </div>
                    <div className="news-grid">
                        {
                            this.state.news.map((n) => {
                                return <News vol={n} key={n.id} />
                            })
                        }
                    </div>
                </div>
                <div className="bs-container">
                    <Bestsellers series={this.state.bs} />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    get_home_products
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(Home);