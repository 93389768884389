import React from "react";
import AddLibro from "./addLibro";
import AddLibroVol from "./addLibroVol";

import BackToDash from '../backToDash/backToDash'
import Loader from "../../../components/loader/loader";
import checkUser from "../checkUser";

class AdminLibros extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: null,
            loading: true
        }
    }

    componentDidMount() {
        this.setState({
            user: this.props.user
        }, ()=>{
            checkUser(this)
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.user !== prevProps.user) {
            this.setState({
                user: this.props.user
            }, ()=>{
                checkUser(this)
            })
        }
    }

    render() {
        if (this.state.loading) return <Loader />
        return (
            <div className="dashboard-main-container">
                <BackToDash />
                <h1 className="dashboard-title">
                    Administrar libros
                </h1>
                <span className="dash-space-container">
                    <AddLibroVol />
                    <AddLibro />
                </span>
            </div>
        )
    }

}

export default AdminLibros