import React from "react"
import Swal from 'sweetalert2'

import './checkout.css'
import '../../swal.css'

import { connect } from 'react-redux'
import { checkout, get_checkout_data, get_ticket } from '../../redux/actions'
import { Local_delivery, Shipping } from './ship/shipClass'
import { auth } from '../../firebase'

import { v4 as uuidv4 } from 'uuid'
import Loader from '../../components/loader/loader'
import Ship from "./ship/ship"
import Payment from './payment/payment'
import { getGuestCart, getGuestDiscount } from "../../redux/actions/guestCartFunctions"
import createMPCard from "./mercadopago"

class Checkout extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            loading: true,
            checkout_data: {},
            user: {},
            form: "ship",
            shipping: 0,
            total: 0
        }
    }

    componentDidMount() {
        this.setState({
            user: this.props.user
        }, () => {
            if (!this.props.user) {
                const user = {
                    id: null,
                    cart: getGuestCart(),
                    discount: getGuestDiscount()
                }

                this.setState({
                    user: user
                })
            }
            this.props.get_checkout_data()
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.state.data !== this.props.state.data) {
            this.setState({
                checkout_data: this.props.state.data,
                loading: false
            })
        }

        if (prevProps.state.ticket !== this.props.state.ticket) {
            console.log(this.props.state.ticket)
            this.setState({
                data: {
                    ...this.state.data,
                    items: this.props.state.ticket.items,
                    total: this.props.state.ticket.total + (this.props.state.ticket.shipping ? this.props.state.ticket.shipping : 0),
                    shipping: this.props.state.ticket.shipping,
                    discount: this.props.state.ticket.discount ? this.props.state.ticket.discount : 0
                },
                shipping: this.props.state.ticket.shipping
            }, () => {
                const total = this.state.data.total - (this.state.data.total * this.state.data.discount / 100)
                this.setState({
                    form: 'payment'
                }, () => {
                    createMPCard(total, this.submitPayment, this.props.checkout)
                })
            })
        }
    }

    submitPayment = () => {
        const venta = {
            id: this.state.data.id,
            date: new Date(),
            user_id: auth.currentUser?.uid || null,
            total: this.state.total,
            payment_method: "card",
            payer: {
                email: document.getElementById('form-checkout__cardholderEmail').value,
                name: document.getElementById('form-checkout__cardholderName').value,
                dni: document.getElementById('form-checkout__identificationNumber').value
            },
            items: this.state.data.items,
            shipping: this.state.data.shipping || null,
            local_delivery: this.state.data.local_delivery || null,
        }

        this.setState({
            data: venta
        }, () => {
            return venta
        })
    }

    getItems = () => {
        const items = []
        const cart = this.state.user?.cart || []
        for (let i = 0; i < cart.length; i++) {
            const item = {
                id: cart[i],
                cant: 1
            }

            let exist = false
            for (let j = 0; j < items.length; j++) {
                if (items[j].id === item.id) {
                    items[j].cant++
                    exist = true
                }
            }

            if (!exist) {
                items.push(item)
            }
        }

        return items
    }

    submitShip = (type) => {
        let purchaseData = {
            user_id: auth.currentUser?.uid || null,
            id: uuidv4(),
            items: [],
            payer: {},
            shipping: null,
            local_delivery: null,
            total: null,
            installments: null
        }

        const ticket_data = {
            checkout: true,
            shipping: null,
            items: this.getItems()
        }

        try {
            if (type === "envio") {
                const shipClass = new Shipping()
                const shipObj = shipClass.getObject()
                ticket_data.shipping = shipObj.loc
                purchaseData.shipping = shipObj
                purchaseData.local_delivery = null

            } else {
                const shipClass = new Local_delivery()
                const shipObj = shipClass.getObject()
                purchaseData.local_delivery = shipObj
                purchaseData.shipping = null
            }

            this.setState({
                data: purchaseData
            }, () => {
                this.props.get_ticket(ticket_data)
            })
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: err
            })
        }
    }

    getForm = (e) => {
        if (e === this.state.form) {
            return { "display": "flex" }
        } else {
            return { "display": "none" }
        }
    }

    getShippingPrice = () => {
        if (!this.state.data.shipping) return 0
        return this.state.shipping
    }

    getDiscount = () => {
        if(this.state.user.tmp_discount){
            return `${this.state.user.tmp_discount.split('%%')[1]}%`
        }else if(this.state.user.discount){
            return `${this.state.user.discount.split('%%')[1]}%`
        }else{
            return '0%'
        }
    }

    render() {
        if (this.state.loading) return <Loader />
        return (
            <div className="checkout-main">
                <section className="checkout-form">
                    <span style={this.getForm('ship')}>
                        <Ship onSubmit={this.submitShip} />
                    </span>
                    <span className="form-checkout-style" style={this.getForm('payment')}>
                        <Payment onSubmit={this.submitPayment} />
                        <section className="ticket-container">
                            <h3 className="ticket-title">
                                Ticket
                            </h3>
                            <table className="ticket-table">
                                <thead>
                                    <tr className="ticket-items">
                                        <th className="ticket-item-title">
                                            Item
                                        </th>
                                        <th className="ticket-item-title">
                                            Precio
                                        </th>
                                        <th className="ticket-item-title">
                                            C/ desc.
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.data.items?.map((v) => {
                                            const with_discount = v.publisher.discount ? (v.price - (v.price * v.publisher.discount / 100)) : v.price
                                            return (
                                                <tr className="ticket-items" key={v.id}>
                                                    <td className="ticket-item-title">{v.title}</td>
                                                    <td className="ticket-item-title">{v.price || null}</td>
                                                    <td className="ticket-item-title">{with_discount}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                <tr className="ticket-items" key="1">
                                </tr>
                                <tr className="ticket-items" key="2">
                                    <td className="ticket-item-title">Envío</td>
                                    <td className="ticket-item-title">{this.getShippingPrice()}</td>
                                </tr>
                                <tr className="ticket-items" key="3">
                                    <td className="ticket-item-title">Descuento</td>
                                    <td className="ticket-item-title">{this.getDiscount()}</td>
                                </tr>
                                <thead>
                                    <tr className="ticket-items">
                                        <th className="ticket-item-title">
                                            Total:
                                        </th>
                                        <th className="ticket-item-title">
                                            {this.state.data.total - (this.state.data.total * this.state.data.discount / 100)}
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </section>
                    </span>
                </section>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    checkout,
    get_checkout_data,
    get_ticket
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(Checkout);