import React from "react"
import queryString from 'query-string'

import Loader from '../../components/loader/loader'
import Card from "../../components/card/card"

import { connect } from 'react-redux'
import { search_products } from "../../redux/actions/index"

import { AiFillCaretRight, AiFillCaretLeft } from 'react-icons/ai'

import './search.css'
import filter from "./filter"
import filterProducts from "./filterProducts"

class Search extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            results: [],
            hash: "",
            name: null,
            products: [],
            firstPage: 0,
            lastPage: 0,
            paginated: [],
            page: 0,
            pageData: true,
            filtered: [],
            filter: ''
        }
    }

    setPages = (results) => {
        const pages = []
        const p = this.state.page
        for (let i = 0; i < 20; i++) {
            if (results[i + (20 * p)]) {
                pages.push(results[i + (20 * p)])
            }
        }

        return pages
    }

    filterSearch = () => {
        this.setState({
            hash: window.location.hash,
            loading: true,
            pageData: false
        }, () => {
            const q = queryString.parse(window.location.hash)
            let name

            for (let key in q) {
                name = q[key];
            }

            name = name.toLowerCase()

            const filtered = this.state.products

            let paginated = []
            let lastPage = 0

            lastPage = Math.floor(filtered.length / 20)

            paginated = this.setPages(filtered)

            this.setState({
                paginated: paginated,
                lastPage: lastPage,
                results: filtered,
                name: name,
                filtered: filtered
            }, () => {
                setTimeout(() => {
                    this.setState({
                        loading: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                pageData: true
                            })
                        }, 250);
                    })
                }, 500);
            })
        })
    }

    componentDidMount() {
        const hash = window.location.hash
        const query = hash.split('q=')[1]
        this.setState({
            loading: true
        }, () => {
            this.props.search_products(query)
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.loc !== prevProps.loc && this.props.loc !== this.state.hash && this.state.products.length > 0) {
            const hash = window.location.hash
            const query = hash.split('q=')[1]
            this.setState({
                loading: true
            }, () => {
                this.props.search_products(query)
            })
        }

        if (prevProps.state.products !== this.props.state.products) {
            this.setState({
                products: this.props.state.products
            })
        }

        if (prevState.products !== this.state.products && this.state.products.length > 0) {
            this.filterSearch()
        }

        if (prevState.page !== this.state.page) {
            const paginated = this.setPages(this.state.filtered)
            this.setState({
                paginated: paginated
            }, () => {
                setTimeout(() => {
                    this.setState({
                        loading: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                pageData: true
                            })
                        }, 250);
                    })
                }, 500);
            })
        }
    }

    getButtonStyle = (e) => {
        if (e === "prevPage") {
            if (document.getElementById("prevPage")) {
                if (this.state.page === 0) {
                    document.getElementById("prevPage").disabled = true
                } else {
                    document.getElementById("prevPage").disabled = false
                }
            }
        } else {
            if (document.getElementById("nextPage")) {
                if (this.state.page === this.state.lastPage) {
                    document.getElementById("nextPage").disabled = true
                } else {
                    document.getElementById("nextPage").disabled = false
                }
            }
        }
    }

    getSyleData = () => {
        if (!this.state.pageData) {
            return { "visibility": "hidden" }
        } else {
            return { "visibility": "visible" }
        }
    }

    changePagePrev = () => {
        this.setState({
            page: this.state.page - 1,
            loading: true
        })
    }

    changePageNext = () => {
        this.setState({
            page: this.state.page + 1,
            loading: true
        })
    }

    filterProducts = (e) => {
        let filter = e.target.id
        if (this.state.filter === filter) {
            filter = ''
        }

        const filtered = filterProducts(this.state.results, filter)
        const paginated = this.setPages(filtered)

        return this.setState({
            filtered: filtered,
            paginated: paginated,
            page: 0,
            filter: filter
        })

    }

    getFilterStyle = (e) => {
        if (this.state.filter === e)
            return { "background-color": "rgb(55, 130, 175, 0.75)" }
        else
            return { "background-color": "transparent" }
    }

    render() {
        if (this.state.loading) {
            return <Loader />
        } else {
            return (
                <div className="search-main-container">
                    <section className="search-title-container">
                        <h1 className="search-title">
                            {`Resultados para '${this.state.name}': ${this.state.results.length}`}
                        </h1>
                    </section>
                    <section className="search-filters-container">
                        <button className="search-filter-button" id='menor' onClick={this.filterProducts}
                            style={this.getFilterStyle('menor')}>
                            Menor precio
                        </button>
                        <button className="search-filter-button" id='mayor' onClick={this.filterProducts}
                            style={this.getFilterStyle('mayor')}>
                            Mayor precio
                        </button>
                        <button className="search-filter-button" id='stock' onClick={this.filterProducts}
                            style={this.getFilterStyle('stock')}>
                            En stock
                        </button>
                    </section>
                    <div className="search-grid">
                        {this.state.paginated.map((v) => {
                            return <Card vol={v.product} />
                        })}
                    </div>
                    <section className="pages-data" style={this.getSyleData()}>
                        <button className="page-button" id="prevPage"
                            style={this.getButtonStyle("prevPage")}
                            onClick={this.changePagePrev}>
                            <AiFillCaretLeft className="page-button-icon" />
                        </button>
                        <button className="page-button" id="nextPage"
                            style={this.getButtonStyle("nextPage")}
                            onClick={this.changePageNext}>
                            <AiFillCaretRight className="page-button-icon" />
                        </button>
                    </section>
                </div>
            )
        }
    }

}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    search_products
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(Search);