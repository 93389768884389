import React from "react";

import "./editoriales.css"
import { Link } from "react-router-dom";

class Editoriales extends React.Component{
    render(){
        return(
            <div className="editoriales-main-container">
                {
                    this.props.editoriales.map((ed)=>{
                        return(
                            <Link to={`/publisher/${ed.id}`} className="editorial-card" key={ed.id}>
                                <img src={ed.img_url} alt={ed.img_url} className="editorial-card-img" />
                                <span className="editorial-card-text-container">
                                    <p className="editorial-card-text">
                                        {ed.name}
                                    </p>
                                </span>
                            </Link>
                        )
                    })
                }
            </div>
        )
    }
}

export default Editoriales