import React from "react";

import '../editorial/editorial.css'

import CardSerie from '../../components/cardSerie/cardSerie'

import { connect } from 'react-redux'
import { get_author } from "../../redux/actions/index"

import Loader from '../../components/loader/loader'

class Author extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            products: null,
            loading: true,
            author: ''
        }
    }

    componentDidMount() {
        const author = decodeURI(window.location.hash.split("/")[2])
        this.setState({
            author: author
        }, ()=>{
            this.props.get_author(author)
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.state.products !== this.props.state.products) {
            this.setState({
                products: this.props.state.products
            }, () => {
                this.setState({
                    loading: false
                })
            })
        }
    }

    render() {
        if (this.state.loading) return <Loader />
        return (
            <div className="editorial-main-container">
                <section className="editorial-title-container">
                    <h2 className="editorial-title">
                        {this.state.author}
                    </h2>
                </section>
                <section className="editorial-products-container">
                    <section className="editorial-all-products">
                        <section className="editorial-products-grid">
                            {
                                this.state.products.map((p) => {
                                    return <CardSerie vol={p} key={p.id} />
                                })
                            }
                        </section>
                    </section>
                </section>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    get_author
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(Author);