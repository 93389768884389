import React from "react";

import { connect } from 'react-redux'
import { simul_venta, getStockProducts, get_ticket } from '../../../redux/actions'

import { auth } from '../../../firebase'

import Loader from "../../../components/loader/loader"

import Swal from 'sweetalert2'

import '../forms.css'
import './simulVenta.css'

class SimulForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            products: {},
            tomos: [],
            items: [],
            total: 0
        }
    }

    componentDidMount() {
        this.props.getStockProducts()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.state.products !== this.props.state.products) {
            this.setState({
                products: this.props.state.products
            }, () => {
                this.setState({
                    loading: false
                })
            })
        }

        if (prevProps.state.ticket !== this.props.state.ticket) {
            let ticket = this.props.state.ticket
            ticket.created = true
            this.setState({
                items: ticket
            })
        }

        if (prevProps.state.status !== this.props.state.status) {
            if (this.props.state.status.code === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Compra creada con éxito!'
                })
                    .then(() => {
                        window.location.reload()
                    })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: this.props.state.status.message
                })
                    .then(() => {
                        window.location.reload()
                    })
            }
        }

    }

    handleChange = (e) => {
        const serie = e.target.value
        const products = this.state.products
        let vols = []
        for (const key in products) {
            if (Object.hasOwnProperty.call(products, key)) {
                const product = products[key]
                if (key !== "news") {
                    for (let i = 0; i < product.length; i++) {
                        if (product[i].serie === serie) {
                            document.getElementById('simulventa-tomo').disabled = false
                            vols = product[i].vols
                        } else if (product[i].title === serie) {
                            document.getElementById('simulventa-tomo').disabled = true
                        }
                    }
                }
            }
        }

        this.setState({
            tomos: vols
        })
    }

    add_item = () => {
        const serie = document.getElementById('simulventa-serie').value
        const title = document.getElementById('simulventa-tomo').value
        const title_exist = document.getElementById('simulventa-tomo').disabled
        if (title_exist) {
            const products = this.state.products.figuras
            for (let i = 0; i < products.length; i++) {
                if (serie === products[i].title) {
                    const vol = {
                        title: products[i].title,
                        cant: 1,
                        id: products[i].id
                    }

                    const items = this.state.items
                    let exist = false
                    for (let i = 0; i < items.length; i++) {
                        if (items[i].id === vol.id) {
                            items[i].cant++
                            exist = true
                        }
                    }

                    if (!exist) {
                        items.push(vol)
                    }

                    return this.setState({
                        items: items
                    })
                }
            }
        }

        const tomos = this.state.tomos
        let vol = null
        for (let i = 0; i < tomos.length; i++) {
            if (tomos[i].title === title) {
                vol = {
                    title: `${serie} ${title}`,
                    cant: 1,
                    id: tomos[i].id
                }
            }
        }

        const items = this.state.items
        let exist = false
        for (let i = 0; i < items.length; i++) {
            if (items[i].id === vol.id) {
                items[i].cant++
                exist = true
            }
        }

        if (!exist) {
            items.push(vol)
        }

        return this.setState({
            items: items
        })
    }

    create_ticket = (e) => {
        this.props.get_ticket({
            items: this.state.items,
            user_id: auth.currentUser.uid || null
        })
    }

    handleUpdate = (e) => {
        e.preventDefault()
        const data = {
            items: this.state.items,
            user_id: auth.currentUser?.uid || null
        }

        this.props.simul_venta(data)

        document.getElementById("simul-add-item").disabled = true
        document.getElementById("simul-create").disabled = true
    }

    create_disabled = () => {
        if (this.state.items.created) return false
        return true
    }

    render() {
        if (this.state.loading) {
            return <Loader />
        } else {
            return (
                <div className="dashboard-main-container">
                    <form className="dash-form-container" onSubmit={this.handleUpdate}>
                        <span className="dash-form-space">
                            <div className="dash-form-column">
                                <input
                                    className="dash-form-input input-nombre"
                                    type="text"
                                    list="simulventa-list"
                                    id="simulventa-serie"
                                    onChange={this.handleChange}
                                    placeholder="Serie/Colección"
                                />
                                <datalist
                                    id="simulventa-list"
                                    className="dash-form-input input-nombre"
                                >
                                    {
                                        this.state.products.mangas.map((p) => {
                                            return (
                                                <option key={`${p.serie_id}-simulventa`}>
                                                    {p.serie}
                                                </option>
                                            )
                                        })
                                    }

                                    {
                                        this.state.products.comics.map((p) => {
                                            return (
                                                <option key={`${p.serie_id}-simulventa`}>
                                                    {p.serie}
                                                </option>
                                            )
                                        })
                                    }

                                    {
                                        this.state.products.libros.map((p) => {
                                            return (
                                                <option key={`${p.serie_id}-simulventa`}>
                                                    {p.serie}
                                                </option>
                                            )
                                        })
                                    }

                                    {
                                        this.state.products.colecciones.map((p) => {
                                            return (
                                                <option key={`${p.serie_id}-simulventa`}>
                                                    {p.serie}
                                                </option>
                                            )
                                        })
                                    }

                                    {
                                        this.state.products.figuras.map((p) => {
                                            return (
                                                <option key={`${p.id}-simulventa`}>
                                                    {p.title}
                                                </option>
                                            )
                                        })
                                    }
                                </datalist>
                                <br />
                                <input
                                    className="dash-form-input input-nombre"
                                    type="text"
                                    list="simulventa-tomo-list"
                                    id="simulventa-tomo"
                                    placeholder="Volumen"
                                />
                                <datalist
                                    id="simulventa-tomo-list"
                                    className="dash-form-input input-nombre"
                                >
                                    {
                                        this.state.tomos.map((p) => {
                                            return (
                                                <option id={p.id} key={`${p.id}-simulventa`}>
                                                    {p.title}
                                                </option>
                                            )
                                        })
                                    }
                                </datalist>
                                <br />
                                <br />
                                <input
                                    className="dash-form-input input-nombre"
                                    type="text"
                                    list="simulventa-figura-list"
                                    id="simulventa-figura"
                                    placeholder="Figura"
                                />
                                <datalist
                                    id="simulventa-figura-list"
                                    className="dash-form-input input-nombre"
                                >
                                    {
                                        this.state.products.figuras.map((p) => {
                                            return (
                                                <option key={`${p.serie_id}-simulventa`}>
                                                    {p.serie}
                                                </option>
                                            )
                                        })
                                    }
                                </datalist>
                            </div>
                        </span>
                        <button className="dash-form-button add-item" id="simul-add-item" type="button" onClick={this.add_item}>
                            Añadir item
                        </button>
                        <section className="ticket-container">
                            <h3 className="ticket-title">
                                Ticket
                            </h3>
                            <table className="ticket-table">
                                <thead>
                                    <tr className="ticket-items">
                                        <th className="ticket-item-title">
                                            Item
                                        </th>
                                        <th className="ticket-item-title">
                                            Cant.
                                        </th>
                                        <th className="ticket-item-title">
                                            Precio
                                        </th>
                                        <th className="ticket-item-title">
                                            C/ desc.
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.items.map((v) => {
                                            console.log("VOLUMEN DEL TICKET: ", v)
                                            return (
                                                <tr className="ticket-items" key={v.id}>
                                                    <td className="ticket-item-title">{v.title}</td>
                                                    <td className="ticket-item-title">{v.cant}</td>
                                                    <td className="ticket-item-title">{v.price || null}</td>
                                                    <td className="ticket-item-title">{v.desc || null}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                <thead>
                                    <tr className="ticket-items">
                                        <th className="ticket-item-title">
                                            Total:
                                        </th>
                                        <th className="ticket-item-title">
                                            {this.state.total}
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </section>
                        <button className="dash-form-button add-item" onClick={this.create_ticket} type="button" id="simul-create">
                            Crear ticket
                        </button>
                        <button
                            className="dash-form-button"
                            type="submit"
                            id="simul-create"
                            disabled={this.create_disabled()}
                        >
                            Crear venta
                        </button>
                    </form>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    simul_venta,
    get_ticket,
    getStockProducts
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(SimulForm);