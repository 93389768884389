export default function setButtons(products){
    const buttons = document.getElementsByClassName("editorial-products-button")
    let selected;
    for(let i = 0; i < buttons.length; i++){
        buttons[i].disabled = true
        if(buttons[i].id === "libros"){
            if(products.books.length > 0){
                buttons[i].disabled = false
            }
        }
        
        if(buttons[i].id === "comics"){
            if(products.comics.length > 0){
                buttons[i].disabled = false
            }
        }
        
        if(buttons[i].id === "mangas"){
            if(products.mangas.length > 0){
                buttons[i].disabled = false
            }
        }
    }

    if(products.mangas.length > 0){
        selected = "mangas"
    }else if(products.comics.length > 0){
        selected = "comics"
    }else if(products.libros){
        if(products.libros.length > 0){
            selected = "libros"
        }
    }

    if(document.getElementById(selected)){
        document.getElementById(selected).style.borderColor = "black"
    }


    return selected;
}