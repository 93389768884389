import React from "react";
import Loader from "../../../components/loader/loader"

import { connect } from 'react-redux'
import { change_price, get_editoriales, get_series_price, get_volumes_price } from '../../../redux/actions'

import BackToDash from '../backToDash/backToDash'

import Swal from 'sweetalert2'

class Precios extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: null,
            editoriales: [],
            series: [],
            volumes: [],
            price: null,
            serie: null,
            editorial: null,
            volume: null,
            type: "Global por editorial",
            loading: true
        }
    }

    componentDidMount() {
        this.props.state.status = null

        this.props.get_editoriales()

        this.setState({
            user: this.props.user
        }, () => {
            if (!this.state.user) {
                window.location.hash = '/'
            } else {
                if (!this.state.user.admin) {
                    window.location.hash = '/'
                }
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user !== this.props.user) {
            this.setState({
                user: this.props.user
            }, () => {
                if (!this.state.user) {
                    window.location.href = '/'
                } else {
                    if (!this.state.user.admin) {
                        window.location.href = '/'
                    }
                }
            })
        }

        if (prevState.user !== this.state.user) {
            if (this.state.user) {
                if (this.state.user.admin) {
                    this.setState({
                        loading: false
                    })
                }
            }
        }

        if (prevProps.state.status !== this.props.state.status && this.props.state.status) {
            Swal.fire({
                icon: "success",
                title: "Precios actualizados con éxito!"
            })
                .then(() => {
                    window.location.reload()
                })
        }

        if (prevProps.state.editoriales !== this.props.state.editoriales) {
            this.setState({
                editoriales: this.props.state.editoriales
            })
        }


        if (prevProps.state.products !== this.props.state.products) {
            this.setState({
                series: this.props.state.products
            })
        }

        if (prevProps.state.volumes !== this.props.state.volumes) {
            this.setState({
                volumes: this.props.state.volumes
            })
        }

    }

    getStyle = (e) => {
        if (e === this.state.type) {
            return { "display": "flex" }
        } else {
            return { "display": "none" }
        }
    }

    selectType = (e) => {
        this.setState({
            type: e.target.value
        }, () => {
            switch (this.state.type) {
                default: return
                case 'Global por editorial':
                    if (this.state.editoriales.length === 0) {
                        this.props.get_editoriales()
                    }

                    break
                case 'Por serie':
                    if (this.state.series.length === 0) {
                        this.props.get_series_price()
                    }

                    break

                case 'Por tomo':
                    if (this.state.series.length === 0) {
                        this.props.get_series_price()
                    }

                    break
            }
        })
    }

    handleUpdate = (e) => {
        e.preventDefault()
        document.getElementById("modify-button").disabled = true

        let type
        if (this.state.type === 'Global por editorial') type = 'global'
        if (this.state.type === 'Por serie') type = 'serie'
        if (this.state.type === 'Por tomo') type = 'volume'

        const data = {
            type: type,
            publisher: this.state.editorial,
            serie: this.state.serie,
            volume: this.state.volume,
            old_price: Number(document.getElementById('old_price').value),
            new_price: Number(document.getElementById('new_price').value),
        }

        this.props.change_price(data)
    }

    getVols = (e) => {
        const name = e.target.value
        const serie = this.state.series.find(s => name === s.name)
        if (!serie.id) return

        this.setState({
            serie: serie.id
        }, () => {
            this.props.get_volumes_price(serie.id)
        })
    }

    select_volume = (e) => {
        const name = e.target.value
        const volume = this.state.volumes.find(v => name === v.name)
        if (!volume) return

        this.setState({
            volume: volume.id,
            price: volume.price
        })
    }

    setSerie = (e) => {
        const name = e.target.value
        const serie = this.state.series.find(s => name === s.name)
        if (!serie.id) return

        this.setState({
            serie: serie.id
        })
    }

    setEditorial = (e) => {
        const name = e.target.value
        const editorial = this.state.editoriales.find(e => name === e.name)
        if (!editorial.id) return

        this.setState({
            editorial: editorial.id
        })
    }

    disabledInput = () => {
        if(this.state.type !== 'Por tomo') return false
        return true
    }

    render() {
        if (this.state.loading) {
            return <Loader />
        } else {
            return (
                <div style={
                    {
                        "display": "flex",
                        "flexDirection": "column",
                        "alignItems": "center",
                        "justifyContent": "center",
                        "marginTop": "25px"
                    }
                }>
                    <BackToDash />
                    <br />
                    <form className="dash-form-container" onSubmit={this.handleUpdate}>
                        <h3 className="dash-form-title">
                            Modificar precios
                        </h3>
                        <select
                            className="dash-form-input"
                            id="modify-type"
                            onChange={this.selectType}>
                            <option key={`global-modifyprecio`} selected={true}>
                                Global por editorial
                            </option>
                            <option key={`serie-modifyprecio`}>
                                Por serie
                            </option>
                            <option key={`tomo-modifyprecio`}>
                                Por tomo
                            </option>
                        </select>
                        <span className="dash-form-space" style={this.getStyle("Global por editorial")}>
                            <div className="dash-form-column">
                                <select
                                    className="dash-form-input input-nombre"
                                    id="modify-editorial"
                                    onChange={this.setEditorial}>
                                    <option key={`-serie`} selected={true} disabled={true}>
                                        Editorial
                                    </option>
                                    {
                                        this.state.editoriales.map((e) => {
                                            return <option key={e.id}>{e.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </span>
                        <span className="dash-form-space" style={this.getStyle("Por serie")}>
                            <div className="dash-form-column">
                                <input
                                    className="dash-form-input input-nombre"
                                    id="modify-serie"
                                    list="series-series"
                                    onChange={this.setSerie}
                                >
                                </input>
                                <datalist id='series-series'>
                                    <option key={`-serie`} selected={true} disabled={true}>
                                        Serie
                                    </option>
                                    {
                                        this.state.series.map((p) => {
                                            return <option key={p.id}>{p.name}</option>
                                        })
                                    }
                                </datalist>
                            </div>
                        </span>
                        <span className="dash-form-space" style={this.getStyle("Por tomo")}>
                            <div className="dash-form-column">
                                <input
                                    className="dash-form-input input-nombre"
                                    id="modify-serie"
                                    list="series-volume"
                                    onChange={this.getVols}
                                >
                                </input>
                                <datalist id='series-volume'>
                                    <option key={`-volume`} selected={true} disabled={true}>
                                        Serie
                                    </option>
                                    {
                                        this.state.series.map((p) => {
                                            return <option key={p.id + '-v'}>{p.name}</option>
                                        })
                                    }
                                </datalist>
                                <br />
                                <select
                                    className="dash-form-input input-nombre"
                                    id="modify-tomo-vol"
                                    onChange={this.select_volume}>
                                    <option key={`-tomo`} selected={true} disabled={true}>
                                        Tomo
                                    </option>
                                    {
                                        this.state.volumes.map((v) => {
                                            return <option key={`${v.id}`}>
                                                {v.name}
                                            </option>
                                        })
                                    }
                                </select>
                                <br />
                                <p className="dash-form-label"
                                    style={
                                        {
                                            "backgroundColor": "rgba(0, 0, 0,0.25)",
                                            "alignSelf": "center",
                                            "padding": "5px",
                                            "borderRadius": "5px",
                                            "paddingLeft": "15px",
                                            "paddingRight": "15px"
                                        }
                                    }>
                                    Precio actual: ${this.state.price}
                                </p>
                            </div>
                        </span>
                        <label htmlFor="old_price" className="dash-form-label">
                            Precio actual
                        </label>
                        <input
                            disabled={this.disabledInput()}
                            className="dash-form-input"
                            id="old_price"
                            type="number"
                            min={0}
                        />
                        <label htmlFor="new_price" className="dash-form-label">
                            Precio nuevo
                        </label>
                        <input
                            className="dash-form-input"
                            id="new_price"
                            type="number"
                            min={0}
                        />
                        <button className="dash-form-button" type="submit" id="modify-button">
                            Modificar
                        </button>
                    </form>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    change_price,
    get_editoriales,
    get_series_price,
    get_volumes_price
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(Precios);