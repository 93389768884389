import React from "react";
import Bestsellers from "../../components/bestsellers/bestsellers";
import Recos from "../../components/recos/recos";
import '../productsStyle.css'
import Editoriales from "../../components/editoriales/editoriales";
import Loader from '../../components/loader/loader';

class ProductPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tomos_unicos: [],
            series_nuevas: [],
            bestsellers: [],
            editoriales: []
        };
    }

    componentDidMount() {
        let limit = 3;
        let bs_limit = 5;
        if (window.innerWidth < 850) bs_limit = 3;
        if (window.innerWidth < 550) { limit = 2; bs_limit = 2; }
        this.props.getProducts(limit, bs_limit);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.state.products !== this.props.state.products) {
            const unicos = {
                vols: this.props.state.products.unicos,
                title: "Tomos únicos"
            };

            const nuevas = {
                vols: this.props.state.products.nuevas,
                title: "Series nuevas"
            };

            this.setState({
                tomos_unicos: unicos,
                series_nuevas: nuevas,
                editoriales: this.props.state.products.editoriales,
                bestsellers: this.props.state.products.bestsellers
            }, () => {
                this.setState({
                    loading: false
                });
            });
        }
    }

    render() {
        if (this.state.loading) return <Loader />

        return (
            <div className="products-main-container">
                <Bestsellers products={this.state.bestsellers} />
                <section className="products-recos-grid">
                    <Recos recos={this.state.tomos_unicos} />
                    <Recos recos={this.state.series_nuevas} />
                </section>
                <section className="products-editoriales-title-container">
                    <h2 className="products-editoriales-title">
                        Editoriales
                    </h2>
                </section>
                <section className="scrollbar products-editoriales">
                    <Editoriales editoriales={this.state.editoriales} />
                </section>
            </div>
        );
    }
}

export default ProductPage;