import React from "react";
import Card from "./card/card";

import { connect } from 'react-redux'
import { get_cart_items, change_cart_cant, apply_discount } from "../../redux/actions";
import Loader from '../../components/loader/loader'

import Swal from 'sweetalert2'

import './cart.css'
import { Link } from "react-router-dom";
import { getGuestDiscount, setGuestDiscount } from "../../redux/actions/guestCartFunctions";

class Cart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: null,
            products: [],
            total: 0,
            loading: true
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                user: this.props.user
            }, () => {
                this.props.get_cart_items()
            })
        }, 1500);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            this.setState({
                user: this.props.user
            }, () => {
                this.props.get_cart_items()
            })
        }

        if (prevProps.state.products !== this.props.state.products) {
            const discount = this.state.user?.tmp_discount?.split('%%')[1] || getGuestDiscount().split('%%')[1]
            const disc_code = this.state.user?.tmp_discount || getGuestDiscount()
            const prod = this.props.state.products.products

            let total_products = 0


            for(let i = 0; i < prod.length; i++){
                console.log(prod[i].price)
                let total = prod[i].price
                if(prod[i].publisher.discount > 0) total = total - (total * prod[i].publisher.discount / 100)
                
                total_products += total
            }



            this.setState({
                products: this.props.state.products.products,
                total: (total_products) - (total_products * discount / 100),
                discount: disc_code
            }, () => {
                this.setState({
                    loading: false
                })
            })
        }

        if (prevProps.state.status !== this.props.state.status) {
            if (this.props.state.status) {
                Swal.fire({
                    icon: 'success',
                    title: 'Carrito actualizado con éxito!'
                })
                    .then(() => {
                        window.location.reload()
                    })
            }
        }
    }

    deleteItem = (e) => {
        e.target.disabled = true

        const cart = this.state.products
        const id = e.target.parentElement.id
        let cant = 0

        for (let i = 0; i < cart.length; i++)
            if (cart[i].id === id) {
                cant = cart[i].qty
            }

        const data = {
            new_cant: cant * (-1),
            id: id,
            user_id: this.state.user.id
        }

        console.log(data)

        this.props.change_cart_cant(data)
    }

    changeCant = (e) => {
        e.target.disabled = true

        const cart = this.state.products
        const id = e.target.parentElement.id
        let cant = 0;

        for (let i = 0; i < cart.length; i++) {
            if (cart[i].id === id) {
                cant = cart[i].qty
            }
        }

        if (parseInt(e.target.value) > cant) {
            const data = {
                cant: cant,
                new_cant: parseInt(e.target.value),
                id: id,
                user: this.state.user
            }

            this.props.change_cart_cant(data)
        } else if (parseInt(e.target.value) < cant) {
            const data = {
                cant: cant,
                new_cant: parseInt(e.target.value),
                id: id,
                user: this.state.user
            }

            this.props.change_cart_cant(data)
        }

    }

    getTotal = (i) => {
        if (i.discount) {
            return `$${i.price * i.qty - (i.price * i.qty * i.discount / 100)}`
        } else {
            return `$${i.price}`
        }
    }

    hasStock = (stock, cant) => {
        if (stock.length > 0) {
            

            return (
                <select className="cart-cant-select" id="item-cant" onChange={this.changeCant}>
                    {
                        stock.map((c) => {
                            if (c === cant) {
                                return <option selected={true} key={Math.random()}>{c}</option>
                            } else {
                                return <option key={Math.random()}>{c}</option>
                            }
                        })
                    }
                </select>
            )
        } else {
            return (
                <span className="cart-no-stock-container">
                    <p className="cart-no-stock">SIN STOCK</p>
                </span>
            )
        }
    }

    setDiscountCode = (e) => {
        e.preventDefault()

        const code = document.getElementById('discount-code').value
        this.props.apply_discount(code)
        return Swal.fire({
            title: 'Aplicando descuento',
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false
        })
    }

    getDiscount = () => {
        if (!this.state.discount && !this.state.discount !== 'abc%%0')
            return <form className="cart--container cart--centered" onSubmit={this.setDiscountCode}>
                <h3 className="discount-title">Código de descuento</h3>
                <input className="discount-input" type="text" id="discount-code" />
                <button className="discount-button" type="submit">Aplicar</button>
            </form>

        return <p>Descuento aplicado {this.state.discount.split('%%')[1]}%- (Código {this.state.discount.split('%%')[0]})</p>
    }

    render() {
        if (this.state.loading) return <Loader />
        if (this.state.products?.length === 0) {
            return (
                <div className="cart-main-container">
                    <h1 className="cart-title">
                        Mi carrito
                    </h1>
                    <div className="cart-card-container">
                        <p className="cart-empty">
                            Tu carrito está vacío!
                        </p>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="cart-main-container">
                    <h1 className="cart-title">
                        Mi carrito
                    </h1>
                    <div className="cart-card-container">
                        {
                            this.state.products.map((i) => {

                                const stock = []

                                for (let j = i.stock; j > 0; j--) {
                                    stock.push(j)
                                }

                                return (
                                    <div className="cart--container">
                                        <Card vol={i} key={i.id} />
                                        <span className="cart-actions">
                                            <span className="cart-inputs" id={i.id}>
                                                <label className="cart-cant-label" htmlFor="item-cant">
                                                    Cant.
                                                </label>
                                                {this.hasStock(stock, i.qty)}
                                                <button onClick={this.deleteItem} className="cart-delete-button">
                                                    Eliminar
                                                </button>
                                                <p className="cart-item-total">
                                                    {this.getTotal(i)}
                                                </p>
                                            </span>
                                        </span>
                                    </div>
                                )
                            })
                        }
                        {
                            this.getDiscount()
                        }
                        <div className="cart-total-container">
                            <span className="cart-total-actions">
                                <p className="cart-total-total">
                                    Total
                                </p>
                                <span className="cart-total">
                                    <p className="cart-total-p">
                                        ${this.state.total}
                                    </p>
                                </span>
                                <Link to='/checkout' className="to-payment">
                                    <p className="to-payment-p">
                                        Proceder al pago
                                    </p>
                                </Link>
                            </span>
                        </div>
                    </div>
                </div>
            )
        }
    }

}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    get_cart_items,
    change_cart_cant,
    apply_discount
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(Cart);