import React from "react"

import { connect } from 'react-redux'
import { advanced_search } from '../../redux/actions'

import './advanced.css'
import '../dashboard/forms.css'

class Advanced extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            query: {},
            editoriales: [],
            products: [],
            minPrecio: 0,
            minTomos: 0
        }
    }

    getMin = (e) => {
        if(e === 'precio'){
            return this.state.minPrecio
        }else{
            return this.state.minTomos
        }
    }

    setTomosMin = (e) => {
        this.setState({
            minTomos: parseInt(e.target.value)
        })
    }

    setPrecioMin = (e) => {
        this.setState({
            minPrecio: parseInt(e.target.value)
        })
    }

    render() {
        return (
            <div className="advanced-main">
                <br />
                <form className="dash-form-container" onSubmit={this.handleSubmit}>
                    <h3 className="dash-form-title">
                        Búsqueda avanzada
                    </h3>
                    <div className="dash-form-space">
                        <section className="dash-form-column">
                            <input
                                className="dash-form-input"
                                id="advanced-serie"
                                type="text"
                                placeholder="Serie"
                            />
                            <br />
                            <input
                                className="dash-form-input"
                                id="advanced-autor"
                                type="text"
                                placeholder="Autor"
                            />
                            <br />
                            <input list="advanced-list-editorial"
                                className="dash-form-input"
                                placeholder="Editorial"
                                id="advanced-editorial"
                            />
                            <datalist
                                className="dash-form-input"
                                id="advanced-list-editorial"
                            >
                                {
                                    this.state.editoriales.map((e) => {
                                        return (
                                            <option key={`${e.id}-addcol`}>
                                                {e.nombre}
                                            </option>
                                        )
                                    })
                                }
                            </datalist>
                        </section>
                        <section className="dash-form-column">
                            <label className="dash-form-label">Precio</label>
                            <span className="dash-form-double">
                                <input
                                    className="dash-form-input input-precio"
                                    id="advanced-price-min"
                                    type="number"
                                    placeholder="min"
                                    min={0}
                                    onChange={this.setPrecioMin}
                                />
                                <input
                                    className="dash-form-input input-precio"
                                    id="advanced-price-max"
                                    type="number"
                                    placeholder="max"
                                    min={this.getMin('precio')}
                                />
                            </span>
                            <label className="dash-form-label">Tomos</label>
                            <span className="dash-form-double">
                                <input
                                    className="dash-form-input input-precio"
                                    id="advanced-tomos-min"
                                    type="number"
                                    placeholder="min"
                                    min={1}
                                    onChange={this.setTomosMin}
                                />
                                <input
                                    className="dash-form-input input-precio"
                                    id="advanced-tomos-max"
                                    type="number"
                                    placeholder="max"
                                    min={this.getMin('tomos')}
                                />
                            </span>
                            <br />
                            <span className="dash-form-checks">
                                <span className="dash-form-double">
                                    <input type="checkbox"
                                    id="advanced-completed"
                                    className="dash-form-input input-check" />
                                    <label htmlFor="advanced-completed" className="dash-form-label no-margin">Serie completa</label>
                                </span>
                                <span className="dash-form-double">
                                    <input type="checkbox"
                                    id="advanced-special"
                                    className="dash-form-input input-check" />
                                    <label htmlFor="advanced-special" className="dash-form-label no-margin">Ed. especial</label>
                                </span>
                            </span>
                        </section>
                    </div>
                    <button className="dash-form-button" type="submit" id="addcolvol-button">
                        Buscar
                    </button>
                </form>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    advanced_search
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(Advanced);