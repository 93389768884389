import React from "react"
import Swal from 'sweetalert2'

import { connect } from 'react-redux'
import { change_stock, get_series_stock, get_volumes_stock } from '../../../redux/actions'
import { auth } from '../../../firebase'
import Loader from "../../../components/loader/loader"

class StockForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            type: 'not set',
            series: [],
            vols: [],
            serie: {},
            volumes: [],
            prod_id: null,
            act_stock: null,
            loading: true
        }
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.state.products !== this.props.state.products) {
            this.setState({
                loading: true
            }, () => {
                this.setState({
                    series: this.props.state.products
                }, this.setState({
                    loading: false
                }))
            })
        }

        if (prevProps.state.volumes !== this.props.state.volumes) {
            this.setState({
                volumes: this.props.state.volumes
            }, this.setState({
                loading: false
            }))
        }

        if (prevProps.state.status !== this.props.state.status) {
            if (this.props.state.status === 'success') {
                Swal.fire({
                    title: "Stock modificado con éxito!"
                })
                    .then(() => {
                        window.location.reload()
                    })
            }
        }
    }

    handleChangeType = (e) => {
        e.preventDefault()
        const _type = e.target.value

        this.setState({
            type: _type
        }, () => {
            let type
            switch (this.state.type) {
                case 'Mangas':
                    type = 'mangas'
                    break
                case 'Cómics':
                    type = 'comics'
                    break
                case 'Colecciones':
                    type = 'collections'
                    break
                case 'Libros':
                    type = 'books'
                    break
                default:
                    type = 'not set'
                    break
            }

            this.props.get_series_stock(type)
        })
    }

    handleChange = (e) => {
        e.preventDefault()
        const name = e.target.value
        const value = this.state.series.find(item => item.name === name)
        if (!value) return

        const serie = this.state.series.find(item => item.name === name)?.id || ''

        this.setState({
            serie: name
        }, () => {
            this.props.get_volumes_stock(serie)
        })
    }

    getProdId = (e) => {
        const name = e.target.value

        const value = this.state.volumes.find(item => item.name === name)
        if (!value) return

        const vol = this.state.volumes.find(v => name === v.name)

        this.setState({
            prod_id: vol.id || '',
            act_stock: vol.stock
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()

        let type
        switch (this.state.type) {
            case 'Mangas':
                type = 'manga'
                break
            case 'Cómics':
                type = 'comic'
                break
            case 'Colecciones':
                type = 'collection'
                break
            case 'Libros':
                type = 'book'
                break
            default:
                type = 'not set'
                break
        }

        const data = {
            type: type,
            id: this.state.prod_id,
            stock: parseInt(document.getElementById('changestock-stock').value),
            user: auth.currentUser?.uid
        }

        Swal.fire({
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            title: "Modificando stock..."
        })

        this.props.change_stock(data)
    }

    render() {
        if (this.state.loading) return <Loader />
        return (
            <form className="dash-form-container" onSubmit={this.handleSubmit}>
                <h3 className="dash-form-title">
                    Modificar stock
                </h3>
                <span className="dash-form-space">
                    <div className="dash-form-column">
                        <select
                            className="dash-form-input input-nombre"
                            onChange={this.handleChangeType}
                            value={this.state.type}
                        >
                            <option key='option-default' value="not set" disabled={true}>
                                Tipo de serie
                            </option>
                            <option key={`mangas-addcol`}>
                                Mangas
                            </option>
                            <option key={`comics-addcol`}>
                                Cómics
                            </option>
                            <option key={`colecciones-addcol`}>
                                Colecciones
                            </option>
                            <option key={`libros-addcol`}>
                                Libros
                            </option>
                        </select>
                        <br />
                        <input list="changestock-serie"
                            className="dash-form-input input-nombre"
                            onChange={this.handleChange}
                            placeholder="Serie/Colección"
                            id="changestock-input-serie"
                        />
                        <datalist
                            className="dash-form-input input-nombre"
                            id="changestock-serie"
                        >
                            {
                                this.state.series.map((p) => {
                                    return (
                                        <option key={p.id}>
                                            {p.name}
                                        </option>
                                    )
                                })
                            }
                        </datalist>
                        <br />
                        <input list="changestock-vol"
                            className="dash-form-input input-nombre"
                            placeholder="Tomo"
                            onChange={this.getProdId}
                        />
                        <datalist
                            className="dash-form-input input-nombre"
                            id="changestock-vol"
                        >
                            {
                                this.state.volumes.map((v) => {
                                    return (
                                        <option key={v.id}>
                                            {v.name}
                                        </option>
                                    )
                                })
                            }
                        </datalist>
                        <br />
                        <input
                            className="dash-form-input"
                            id="changestock-stock"
                            type="number"
                            placeholder="Sumar stock"
                        />
                    </div>
                </span>

                <p className="dash-form-info">Stock actual: <b>{this.state.act_stock}</b></p>
                <button className="dash-form-button" id="addcol-button" type="submit">
                    Cambiar
                </button>
            </form>
        )
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    change_stock,
    get_series_stock,
    get_volumes_stock
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(StockForm);