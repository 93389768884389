import React from "react"

import BackToDash from '../backToDash/backToDash'
import Loader from "../../../components/loader/loader"

import { auth, storage } from '../../../firebase'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"

import { connect } from 'react-redux'
import { add_editorial } from '../../../redux/actions'

import Swal from 'sweetalert2'

import checkUser from '../checkUser'

class AdminEditorial extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: null,
            loading: true,
            img: "https://firebasestorage.googleapis.com/v0/b/proyecto-comiqueria.appspot.com/o/covers%2Fno-cover.jpg?alt=media&token=badf49a6-465c-414a-8443-814e52583cab",
            submitted: false
        }
    }

    componentDidMount() {
        this.setState({
            user: this.props.user
        }, () => {
            checkUser(this)
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.user !== prevProps.user) {
            this.setState({
                user: this.props.user
            }, () => {
                checkUser(this)
            })
        }

        if (prevProps.state.status !== this.props.state.status && this.state.submitted) {
            document.getElementById('addeditorial-button').disabled = true
            if (this.props.state.status.code === 200) {
                return Swal.fire({
                    icon: 'success',
                    title: "Tomo agregado con éxito!"
                })
                    .then(() => {
                        window.location.reload()
                    })
            } else {
                return Swal.fire({
                    icon: 'error',
                    title: this.props.state.status.message
                })
            }
        }
    }

    handleUpload = (e) => {
        var file = e.target.files[0]
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            this.setState({
                img: reader.result
            })
        }
        reader.onerror = function (error) {
            console.log('Error IMG: ', error)
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        document.getElementById('addeditorial-button').disabled = true

        let name = document.getElementById('addeditorial-name').value
        name = name.trim()
        if (name === '') {
            return Swal.fire({
                icon: "error",
                title: "Ingrese un nombre válido!"
            })
        }

        const cover = document.getElementById('addeditorial-img').files[0]
        const storageRef = ref(storage, `/editoriales/${cover.name}`)
        const task = uploadBytesResumable(storageRef, cover)

        task.on(
            "state_changed",
            (snapshot) => {
                console.log(snapshot)
            },
            (error) => {
                console.log(error.message);
            },
            () => {
                getDownloadURL(task.snapshot.ref).then((downloadURL) => {
                    this.setState({
                        submitted: true
                    }, () => {
                        const editorial = {
                            imgURL: downloadURL,
                            name: name,
                            user_id: auth.currentUser?.uid
                        }

                        this.props.add_editorial(editorial)
                        return Swal.fire({
                            title: 'Creando editorial...',
                            showConfirmButton: false,
                            allowEnterKey: false,
                            allowEscapeKey: false,
                            allowOutsideClick: false
                        })
                    })
                })
            }
        )
    }

    render() {
        if (this.state.loading) {
            return <Loader />
        } else {
            return (
                <div className="dashboard-main-container">
                    <BackToDash />
                    <h1 className="dashboard-title">
                        Administrar editoriales
                    </h1>
                    <form className="dash-form-container" onSubmit={this.handleSubmit}>
                        <h3 className="dash-form-title">
                            Agregar editorial
                        </h3>
                        <label htmlFor="addeditorial-name" className="dash-form-label first-label">
                            Nombre
                        </label>
                        <input
                            className="dash-form-input input-nombre"
                            id="addeditorial-name"
                            type="text"
                        />
                        <br />
                        <img className="dash-form-img"
                            id="addeditorial-img-src"
                            src={this.state.img}
                            alt="cover" />
                        <label htmlFor="addeditorial-img" className="dash-form-label cover">
                            Cover
                        </label>
                        <input
                            className="dash-form-input"
                            id="addeditorial-img"
                            type="file"
                            onChange={this.handleUpload}
                        />

                        <button className="dash-form-button" id="addeditorial-button" type="submit">
                            Crear
                        </button>
                    </form>
                </div>
            )
        }
    }

}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    add_editorial
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(AdminEditorial)