import React from 'react'

import "./pageBuilding.css";

class Building extends React.Component{
    render(){
        return(
            <div className='build-main-container'>
                <span class="title-container">
                    <h1 class="title">
                        Página en renovación
                    </h1>
                </span>
            </div>
        )
    }
}

export default Building;