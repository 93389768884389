import React from "react"
import { connect } from 'react-redux'

import { get_serie } from "../../redux/actions/index"
import Loader from '../../components/loader/loader'
import Card from '../../components/card/card'

import './seriePage.css'

class SeriePage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            serie: {},
            hash: null,
            type: ''
        }
    }

    getSerie = () => {
        this.setState({
            loading: true
        }, () => {
            setTimeout(() => {
                const url = window.location.href
                const hash = window.location.hash
                const idIndex = url.lastIndexOf('/') + 1
                const id = url.substring(idIndex)

                this.setState({
                    hash: hash
                }, () => {
                    this.props.get_serie(id)
                })

            }, 250)
        })
    }

    componentDidMount() {
        this.getSerie()
    }

    componentDidUpdate(prevProps) {
        if (this.props.loc !== prevProps.loc && this.props.loc !== this.state.hash) {
            this.getSerie()
        }

        if (this.props.state.serie !== prevProps.state.serie) {
            if (this.props.state.serie.status === 200)
                return this.setState({
                    serie: this.props.state.serie.data
                }, () => {
                    console.log(this.state.serie)
                    this.setState({
                        loading: false
                    })
                })
            if (this.props.state.serie.status === 204)
                return this.setState({
                    serie: null
                }, () => {
                    this.setState({
                        loading: false
                    })
                })
        }
    }

    serieNotFound = () => {
        return (
            <div className="serie-page-main-container">
                <h1 className="serie-page-error-title">
                    NOT FOUND
                </h1>
                <p className="serie-page-error-description">
                    La serie que intentas buscas no existe.
                </p>
            </div>
        )
    }

    getAuthors = () => {
        const authors = this.state.serie.authors
        if(!authors) return <></>
        return (
            <span className="serie-info-span">
                <p className="serie-info-span-title">
                    Autor/es:
                </p>
                {authors.writer && (
                    <p className="serie-info-span-text">
                        {authors.writer}
                    </p>
                )}
                {authors.drawer && (
                    <p className="serie-info-span-text">
                        {authors.drawer}
                    </p>
                )}
                {authors.colourist && (
                    <p className="serie-info-span-text serie-link">
                        {authors.colourist}
                    </p>
                )}
                {authors.inker && (
                    <p className="serie-info-span-text serie-link">
                        {authors.inker}
                    </p>
                )}
                {authors.others && (
                    <p className="serie-info-span-text serie-link">
                        {authors.others}
                    </p>
                )}
            </span>
        )
    }
    

    render() {
        if (this.state.loading) return <Loader />
        if (!this.state.serie) return this.serieNotFound()
        return (
            <div className="serie-page-main-container">
                <section className="serie-title-container">
                    <h1 className="serie-title">
                        {this.state.serie.name}
                    </h1>
                </section>
                <section className="serie-info-main-container">
                    <section className="serie-info-container info">
                        <h3 className="serie-info-title">
                            Info
                        </h3>
                        <section className="serie-info">
                            {this.getAuthors()}
                            <span className="serie-info-span">
                                <p className="serie-info-span-title">
                                    Editorial:
                                </p>
                                <a href={`#/publisher/${this.state.serie.publisher.id}`} className="serie-info-span-text serie-link">
                                    {this.state.serie.publisher.name}
                                </a>
                            </span>
                            <span className="serie-info-span">
                                <p className="serie-info-span-title">
                                    Publicados:
                                </p>
                                <p className="serie-info-span-text">
                                    {this.state.serie.vols.length}
                                </p>
                            </span>
                        </section>
                    </section>
                    <section className="serie-info-container">
                        <h3 className="serie-info-title">
                            Sinopsis
                        </h3>
                        <section className="serie-info-sinopsis-container">
                            <p className="serie-info-sinopsis">
                                {this.state.serie.synopsis}
                            </p>
                        </section>
                    </section>
                </section>
                <section className="serie-volumes-container">
                    <h3 className="serie-volumes-title">Tomos</h3>
                    <section className="serie-volumes-grid">
                        {
                            this.state.serie.vols.map((v) => {
                                return <Card vol={v} key={v.id} />
                            })
                        }
                    </section>
                </section>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    get_serie
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(SeriePage)