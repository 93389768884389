import React from "react";

import './editorial.css'

import CardSerie from '../../components/cardSerie/cardSerie'

import setButtons from './setButtons.js'

import { connect } from 'react-redux'
import { get_editorial } from "../../redux/actions/index"
import { Link } from "react-router-dom";

import Loader from '../../components/loader/loader'

class Editorial extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            editorial: null,
            loading: true,
            id: null,
            selected: null
        }
    }

    componentDidMount() {
        const id = window.location.hash.split("/")[2]
        this.props.get_editorial(id)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.state.editoriales !== this.props.state.editoriales) {
            this.setState({
                editorial: this.props.state.editoriales
            }, () => {
                console.log(this.state.editorial)
                this.setState({
                    loading: false
                }, () => {
                    setButtons(this.state.editorial.products)
                    let selected = null
                    if (this.state.editorial.products.mangas.length > 0) {
                        selected = 'mangas'
                    } else if (this.state.editorial.products.comics.length > 0) {
                        selected = 'comics'
                    } else {
                        selected = 'libros'
                    }
                    this.setState({
                        selected: selected
                    })
                })
            })
        }
    }

    _setProducts = (e) => {
        const id = e.target.id
        this.setState({
            selected: id
        }, () => {
            const buttons = document.getElementsByClassName("editorial-products-button")
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].style.borderColor = "transparent"
                if (buttons[i].id === id) {
                    buttons[i].style.borderColor = "black"
                }
            }
        })
    }

    getSpecials = () => {
        const specials = this.state.editorial.recomendations.specials
        if (specials.length > 0) {
            return (
                <section className="editorial-recos-main scrollbar">
                    <h2 className="editorial-recos-main-title">
                        Ediciones especiales
                    </h2>
                    <section className="editorial-recos-products special scrollbar">
                        {
                            specials.map((s) => {
                                return (
                                    <div className="editorial-special scrollbar" key={s.id}>
                                        <img className="editorial-special-img"
                                            alt="special-cover"
                                            src={s.img_url} />
                                        <p className="editorial-special-title">
                                            {s.title}
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </section>
                </section>
            )
        } else {
            return (
                <section className="editorial-recos-main">
                    <h2 className="editorial-recos-main-title">
                        Ediciones especiales
                    </h2>
                    <p className="editorial-not-recos">
                        No hay ediciones especiales
                    </p>
                </section>
            )
        }
    }

    getAuthors = (type) => {
        let authors = null
        switch (type) {
            case 'mangas':
                authors = this.state.editorial.recomendations.authors?.authors_manga
                break
            case 'comics':
                authors = this.state.editorial.recomendations.authors?.authors_comic
                break
            case 'libros':
                authors = this.state.editorial.recomendations.authors?.authors_libro
                break
            default:
                return []
        }
        if (authors && authors.length > 0) {
            return (
                <section className="editorial-recos-main scrollbar">
                    <h2 className="editorial-recos-main-title">
                        Autores destacados
                    </h2>
                    <section className="editorial-recos-products scrollbar">
                        {
                            authors.map((author) => {
                                return (
                                    <div className="editorial-recos scrollbar" key={author.name}>
                                        <h4 className="editorial-recos-title">
                                            {author.name}
                                        </h4>
                                        <section className="editorial-recos-grid scrollbar">
                                            {
                                                author.series.map((serie) => {
                                                    return (
                                                        <Link to={`/serie/${serie.id}`} className="editorial-recos-serie" key={serie.id}>
                                                            <img alt="reco-img"
                                                                src={serie.img}
                                                                className="editorial-recos-img" />
                                                        </Link>
                                                    )
                                                })
                                            }
                                        </section>
                                    </div>
                                )
                            })
                        }
                    </section>
                </section>
            )
        } else {
            return (
                <section className="editorial-recos-main">
                    <h2 className="editorial-recos-main-title">
                        Autores destacados
                    </h2>
                    <p className="editorial-not-recos">
                        No hay autores destacados
                    </p>
                </section>
            )
        }
    }

    getRecos = () => {
        if (this.state.selected === "mangas") {
            return (
                <section className="editorial-recos-container scrollbar">
                    {this.getAuthors('mangas')}
                    {this.getSpecials()}
                </section>
            )
        } else if (this.state.selected === "comics") {
            return (
                <section className="editorial-recos-container scrollbar">
                    {this.getAuthors('comics')}
                </section>
            )
        } else if (this.state.selected === "libros") {
            return (
                <section className="editorial-recos-container scrollbar">
                    {this.getAuthors('libros')}
                </section>
            )
        }
    }

    render() {
        if (!this.state.loading && !this.state.editorial) {
            return (
                <div className="editorial-main-container">
                    No hay editorial
                </div>
            )
        } else if (this.state.loading) {
            return <Loader />
        } else {
            return (
                <div className="editorial-main-container">
                    <img className="editorial-img" src={this.state.editorial.img_url} alt="img-editorial" />
                    <section className="editorial-title-container">
                        <h2 className="editorial-title">
                            {this.state.editorial.name}
                        </h2>
                    </section>
                    <section className="editorial-products-container">
                        <section className="editorial-products-buttons">
                            <button className="editorial-products-button first"
                                id="mangas"
                                onClick={this._setProducts}>
                                Mangas
                            </button>
                            <button className="editorial-products-button"
                                id="comics"
                                onClick={this._setProducts}>
                                Cómics
                            </button>
                            <button className="editorial-products-button last"
                                id="libros"
                                onClick={this._setProducts}>
                                Libros
                            </button>
                        </section>
                        {this.getRecos()}
                        <section className="editorial-all-products">
                            <h2 className="editorial-recos-main-title">
                                Todas las series
                            </h2>
                            <section className="editorial-products-grid">
                                {
                                    this.state.editorial.products.mangas.map((p) => {
                                        return <CardSerie vol={p} key={p.id} />
                                    })
                                }
                            </section>
                        </section>
                    </section>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    get_editorial
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(Editorial);