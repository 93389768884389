import React from "react";
import get_title from "../../get_title";

import { Link } from 'react-router-dom'

import { BsBookmarkStarFill, BsCartFill } from 'react-icons/bs'

import './card.css'

class CardRelated extends React.Component{
    constructor(props){
        super(props)
    }

    componentDidMount(){
        
    }

    addToCart = (e) => {
        e.preventDefault()
        console.log("HOLIIII")
    }

    hasStock = () => {
        if(this.props.vol.stock > 0){
            return(
                <span className="card-actions">
                    <button className="action-button" onClick={this.addToCart}>
                        <BsBookmarkStarFill className="action-icon" />
                    </button>
                    <button className="action-button" onClick={this.addToCart}>
                        <BsCartFill className="action-icon" />
                    </button>
                </span>
            )
        }else{
            return(
                <span className="card-actions no-stock">
                    <p className="sin-stock-p">
                        Sin stock
                    </p>
                </span>
            )
        }
    }


    render(){
        return(
            <Link to={`/product/${this.props.vol.id}`} className="card-main-container related">
                <img className="card-img" alt="cover" src={this.props.vol.imgURL} />
                <section className="card-info">
                    <p className="card-title">
                        {get_title(this.props.vol)}
                    </p>
                    {this.hasStock()}
                </section>
            </Link>
        )
    }
}

export default CardRelated