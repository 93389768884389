import React from 'react'

import { Routes, Route } from 'react-router-dom';
import AdminStock from './dashboard/adminStock/adminStock';
import AdminVentas from './dashboard/adminVentas/adminVentas';
import Dashboard from './dashboard/dashboard';
import Precios from './dashboard/precios/precios';
import SimulVenta from './dashboard/simulVenta/simulVenta';

import "./pageComponent.css"
import Cart from './cart/cart';
import AdminComics from './dashboard/adminComics/adminComics';
import AdminFiguras from './dashboard/adminFiguras/adminFiguras';
import AdminLibros from './dashboard/adminLibros/adminLibros';
import AdminMangas from './dashboard/adminMangas/adminMangas';
import Editorial from './editorial/editorial';
import Mangas from './productPage/mangas/mangas'
import Comics from './productPage/comics/comics';
import Libros from './productPage/libros/libros';
import Product from './product/product';
import Search from './search/search';
import Home from './home/home';
import SeriePage from './seriePage/seriePage';
import Advanced from './advancedSearch/advanced';
import Checkout from './checkout/checkout';
import Figuras from './figuras/figuras';
import AddEditorial from './dashboard/createEditorial/addEditorial';
import Author from './author/author';
import Descuentos from './dashboard/descuentos/descuentos';

class PageComponent extends React.Component {
    render() {
        return (
            <section className='page-component'>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/mangas' element={<Mangas />} />
                    <Route path='/comics' element={<Comics />} />
                    <Route path='/books' element={<Libros />} />
                    <Route path='/figuras' element={<Figuras />} />

                    <Route path='/author/:author' element={<Author />} />
                    <Route path='/publisher/:id' element={<Editorial />} />

                    <Route path='/search?' element={<Search
                        loc={this.props.loc} />} />
                    <Route path='/advanced' element={<Advanced />}
                    />
                    <Route path='/cart' element={<Cart
                        user={this.props.user} />} />
                    <Route path='/product/:id' element={<Product
                        loc={this.props.loc} />} />
                    <Route path='/serie/:id' element={<SeriePage
                        loc={this.props.loc} />} />


                    <Route path='/dashboard' element={<Dashboard
                        user={this.props.user} />}
                    />
                    <Route path='/admineditoriales' element={<AddEditorial
                        user={this.props.user} />}
                    />
                    <Route path='/adminmangas' element={<AdminMangas
                        user={this.props.user} />}
                    />
                    <Route path='/admincomics' element={<AdminComics
                        user={this.props.user} />}
                    />
                    <Route path='/adminlibros' element={<AdminLibros
                        user={this.props.user} />}
                    />
                    <Route path='/adminfiguras' element={<AdminFiguras
                        user={this.props.user} />}
                    />
                    <Route path='/adminstock' element={<AdminStock
                        user={this.props.user} />}
                    />
                    <Route path='/adminventas' element={<AdminVentas
                        user={this.props.user} />}
                    />
                    <Route path='/simulventa' element={<SimulVenta
                        user={this.props.user} />}
                    />
                    <Route path='/adminprecios' element={<Precios
                        user={this.props.user} />}
                    />
                    <Route path='/discounts' element={<Descuentos user={this.props.user} />}
                    />

                    <Route path='/checkout' element={<Checkout
                        user={this.props.user} />}
                    />
                </Routes>
            </section>
        )
    }
}

export default PageComponent;