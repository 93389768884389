import { connect } from 'react-redux'
import { get_libros_products } from "../../../redux/actions/index"
import ProductPage from "../productPage"  // Importa la clase base

class Libros extends ProductPage {
    // Puedes agregar métodos específicos o sobreescribir los de la clase base si es necesario
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    getProducts: get_libros_products  // Mapea el método específico
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(Libros)
