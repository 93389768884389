import Swal from "sweetalert2"

const errors = [
    {
        code: "parameter cardNumber can not be null/empty",
        message: "El número de tarjeta no puede ser nulo/vacío!"
    },
    {
        code: "parameter cardExpirationMonth can not be null/empty",
        message: "El mes de venc. de la tarjeta no puede ser nulo/vacío!"
    },
    {
        code: "parameter cardExpirationYear can not be null/empty",
        message: "El año de venc. de la tarjeta no puede ser nulo/vacío!"
    },
    {
        code: "invalid parameter cardNumber",
        message: "ingrese un número de tarjeta válido!"
    },
    {
        code: "parameter cardholderName can not be null/empty",
        message: "El nombre del títular no puede ser nulo/vacío!"
    },
    {
        code: "parameter identificationNumber can not be null/empty",
        message: "El DNI del títular no puede ser nulo/vacío!"
    },
    {
        code: "parameter securityCode can not be null/empty",
        message: "El código de seguridad de la tarjeta no puede ser nulo/vacío!"
    },
    {
        code: "invalid parameter cardExpirationMonth",
        message: "Ingrese una fecha de vencimiento válida!"
    },
    {
        code: "invalid parameter securityCode",
        message: "Ingrese un código de seguridad válido!"
    },
    {
        code: "invalid parameter securityCode",
        message: "Ingrese un código de seguridad válido!"
    },
    {
        code: "Invalid parameter 'cardholder.identification.number'",
        message: "El número de documento ingresado no es válido!"
    },
    {
        code: "The customer can't be equal to the collector.",
        message: "El comprador no puede ser igual que el vendedor!"
    },
    {
        code: "Invalid card_token_id",
        message: "Los datos ingresados no son correctos!"
    },
    {
        code: "Invalid card_number_validation",
        message: "El número de tarjeta ingresado no es válido!"
    },
    {
        code: "cc_rejected_other_reason",
        message: "Pago rechazado, por favor vuelva a intentarlo."
    },
]

export default function handleErrors(error){
    if(error.status === 400){
        for(let j = 0; j < errors.length; j++){
            if(errors[j].code === error.status_detail){
                return Swal.fire({
                    icon: "error",
                    title: errors[j].message
                })
            }
        }

        return Swal.fire({
            icon: "error",
            title: error.status_detail
        })

    }else{
        for(let i = 0; i < error.length; i++){
            for(let j = 0; j < errors.length; j++){
                if(errors[j].code === error[i].message){
                    return Swal.fire({
                        icon: "error",
                        title: errors[j].message
                    })
                }
            }
        }

        return Swal.fire({
            icon: "error",
            title: error.status_detail
        })
    }
}