import React from "react"
import localidades from './localidades'
import provincias from './provincias'

import '../../dashboard/forms.css'

class Ship extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            form: 'retira',
            loc: localidades,
            provinces: provincias,
            selected: []
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.props.onSubmit(this.state.form)
    }

    getForm = (e) => {
        if (this.state.form === e) {
            return { "display": "flex" }
        } else {
            return { "display": "none" }
        }
    }

    setForm = (e) => {
        const value = e.target.value
        if (value === 'Retiro en el local') {
            return this.setState({
                form: 'retira'
            })
        } else {
            return this.setState({
                form: 'envio'
            })
        }
    }

    getLocs = (e) => {
        const selected = []
        const province = e.target.value
        console.log(province)

        for (let i = 0; i < this.state.loc.length; i++) {
            if (this.state.loc[i].provincia_nombre === province) {
                selected.push(this.state.loc[i].nombre)
            }
        }

        const filtered = [...new Set(selected)]

        return this.setState({
            selected: filtered
        })

    }

    render() {
        return (
            <form className="dash-form-container" onSubmit={this.handleSubmit}>
                <h3 className="dash-form-title envio-title">
                    Datos de envío
                </h3>
                <br />
                <select id="ship-type" className="dash-form-input input-envio" onChange={this.setForm}>
                    <option>Retiro en el local</option>
                    <option>Envío a domicilio</option>
                </select>
                <span className="dash-form-space" id="retira" style={this.getForm('retira')}>
                    <span className="dash-form-column">
                        <input
                            className="dash-form-input input-envio"
                            id="retira-name"
                            type="text"
                            placeholder="Nombre completo"
                        />
                        <input
                            className="dash-form-input input-envio"
                            id="retira-dni"
                            type="number"
                            placeholder="DNI"
                        />
                        <input
                            className="dash-form-input input-envio"
                            id="retira-email"
                            type="email"
                            placeholder="Email"
                        />
                    </span>
                </span>
                <span className="dash-form-space" id="envio" style={this.getForm('envio')}>
                    <span className="dash-form-column">
                        <input
                            className="dash-form-input input-envio"
                            id="ship-name"
                            type="text"
                            placeholder="Nombre completo"
                        />
                        <input
                            className="dash-form-input input-envio"
                            id="ship-dni"
                            type="number"
                            placeholder="DNI"
                        />
                        <input
                            className="dash-form-input input-envio"
                            id="ship-email"
                            type="email"
                            placeholder="Email"
                        />
                        <input
                            className="dash-form-input input-envio"
                            id="ship-province"
                            type="text"
                            placeholder="Provincia"
                            list="ship-province-list"
                            onChange={this.getLocs}
                        />
                        <datalist id="ship-province-list">
                            {
                                this.state.provinces.map((p) => {
                                    return <option key={p}>
                                        {p}
                                    </option>
                                })
                            }
                        </datalist>
                    </span>
                    <span className="dash-form-column">
                        <input
                            className="dash-form-input input-envio"
                            id="ship-loc"
                            type="text"
                            placeholder="Localidad"
                            list="ship-loc-list"
                        />
                        <datalist id="ship-loc-list">
                            {
                                this.state.selected.map((s) => {
                                    return <option key={s}>
                                        {s}
                                    </option>
                                })
                            }
                        </datalist>
                        <input
                            className="dash-form-input input-envio"
                            id="ship-cp"
                            type="text"
                            placeholder="Código postal"
                        />
                        <input
                            className="dash-form-input input-envio"
                            id="ship-street"
                            type="text"
                            placeholder="Calle"
                        />
                        <input
                            className="dash-form-input input-envio"
                            id="ship-number"
                            type="number"
                            placeholder="Número"
                        />
                    </span>
                </span>
                <br />
                <button className="dash-form-button" id="addcol-button" type="submit">
                    Continuar con el pago
                </button>
            </form>
        )
    }
}

export default Ship