import './App.css';
import MainComponent from './mainComponent';

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function App() {

  const location = useLocation();
  const [url, setUrl] = useState()
  
  useEffect(() => {
      setUrl(window.location.hash)
      window.scrollTo(0, 0)
  }, [location]);

  return (
    <div className="App">
      <MainComponent loc={url} />
    </div>
  );
}

export default App;
