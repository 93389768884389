import React from "react";

import { connect } from 'react-redux'
import { simul_venta } from '../../../redux/actions'

import Loader from "../../../components/loader/loader";
import BackToDash from "../backToDash/backToDash";

import SimulForm from './form'

import '../forms.css'
import './simulVenta.css'

class SimulVenta extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: null,
            loading: true,
            products: [],
            tomos: [],
            venta: [],
            total: {
                total: 0,
                desc: 0
            }
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                user: this.props.user
            }, () => {
                this.state.products.sort((a, b) => a.nombre.localeCompare(b.nombre))
                if (!this.state.user) {
                    window.location.hash = '/'
                } else {
                    if (!this.state.user.admin) {
                        window.location.hash = '/'
                    }
                }
            })
        }, 2500);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            this.setState({
                user: this.props.user
            }, () => {
                if (!this.state.user) {
                    window.location.href = '/'
                } else {
                    if (!this.state.user.admin) {
                        window.location.href = '/'
                    }
                }
            })
        }

        if (prevState.user !== this.state.user) {
            if (this.state.user) {
                if (this.state.user.admin) {
                    this.setState({
                        loading: false
                    })
                }
            }
        }
    }

    render() {
        if (this.state.loading) {
            return <Loader />
        } else {
            return (
                <div className="dashboard-main-container">
                    <BackToDash />
                    <h1 className="dashboard-title">
                        Crear venta
                    </h1>
                    <SimulForm />
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    simul_venta
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(SimulVenta);