function validate(field, value) {
    const regex = {
        email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        dni: /^[1-9]\d{6,7}$/,
        blank: /^[a-zA-Z0-9]+(?:\s+[a-zA-Z0-9]+)*$/
    }

    return regex[field].test(value)
}

/*
adressee: Bautista Manolizi,
dni: 42643494,
street: Medeyros,
num: 736,
dpt: null,
province: Buenos Aires,
loc: Ituzaingó,
tracking: 123456789,
dispatched: true
*/

export class Shipping {
    constructor() {
        this.blankFields = [
            "adressee",
            "street",
            "num",
            "province",
            "loc",
            "cp"
        ]

        this.fields = {}

        this.readFields()
        this.validateFields()

        this.fields.tracking = ""
        this.fields.dispatched = false
    }

    readFields = () => {
        try {
            this.fields = {
                adressee: document.getElementById("ship-name")?.value,
                dni: document.getElementById("ship-dni")?.value,
                email: document.getElementById("ship-email")?.value,
                street: document.getElementById("ship-street")?.value,
                num: document.getElementById("ship-number")?.value,
                province: document.getElementById("ship-province")?.value,
                loc: document.getElementById("ship-loc")?.value,
                cp: document.getElementById("ship-cp")?.value
            }
        } catch (err) {
            throw new Error(err)
        }
    }

    validateFields = () => {
        for (let i = 0; i < this.blankFields.length; i++) {
            if (!validate('blank', this.fields[this.blankFields[i]])) {
                throw new Error(`El campo ${this.blankFields[i]} no es válido!`)
            }
        }

        if (!validate('email', this.fields['email'])) {
            throw new Error(`El campo email no es válido!`)
        }

        if (!validate('dni', this.fields['dni'])) {
            throw new Error(`El campo dni no es válido!`)
        }

    }

    getObject = () => {
        return { ...this.fields }
    }

}

export class Local_delivery {
    constructor() {
        this.fields = {}

        this.readFields()
        this.validateFields()

        this.delivered = false
    }

    readFields = () => {
        try {
            this.fields = {
                name: document.getElementById("retira-name")?.value,
                dni: document.getElementById("retira-dni")?.value,
                email: document.getElementById("retira-email")?.value,
            }
        } catch (err) {
            throw new Error(err)
        }
    }

    validateFields = () => {
        if (!validate('blank', this.fields['name'])) {
            throw new Error(`El campo name no es válido!`)
        }

        if (!validate('email', this.fields['email'])) {
            throw new Error(`El campo email no es válido!`)
        }

        if (!validate('dni', this.fields['dni'])) {
            throw new Error(`El campo dni no es válido!`)
        }

    }

    getObject = () => {
        return { ...this.fields }
    }
}