import React from "react"

import Loader from '../../components/loader/loader'

import { connect } from 'react-redux'
import { add_cart_item, get_product } from "../../redux/actions/index"

import { auth } from '../../firebase'

import CardRelated from '../../components/cardRelated/cardRelated'

import Swal from 'sweetalert2'

import './product.css'
import get_related from "./get_related"
import { getSideInfoCol, getSideInfoComic, getSideInfoLibro, getSideInfoManga } from "./sideInfo"

class Product extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            product: {},
            related: [],
            hash: ""
        }
    }

    componentDidMount() {
        const url = window.location.href
        const hash = window.location.hash
        const idIndex = url.lastIndexOf('/') + 1
        const id = url.substring(idIndex)

        this.setState({
            hash: hash
        }, () => {
            this.props.get_product(id)
        })

    }

    componentDidUpdate(prevProps) {

        if (prevProps.state.product !== this.props.state.product) {
            console.log(this.props.state.product)
            this.setState({
                product: this.props.state.product
            }, () => {
                this.setState({
                    loading: false
                })
            })
        }

        if (this.props.loc !== prevProps.loc && this.props.loc !== this.state.hash) {
            this.setState({
                loading: true
            }, () => {
                const url = window.location.href
                const hash = window.location.hash
                const idIndex = url.lastIndexOf('/') + 1
                const id = url.substring(idIndex)

                this.setState({
                    hash: hash
                }, () => {
                    this.props.get_product(id)
                })
            })
        }

        if (prevProps.state.status !== this.props.state.status) {
            if (this.props.state.status) {
                Swal.fire({
                    icon: 'success',
                    title: "Agregado exitosamente!"
                }).then(() => {
                    window.location.reload()
                })
            } else if (this.props.state.error) {
                Swal.fire({
                    icon: 'error',
                    title: this.props.state.error
                }).then(() => {
                    document.getElementById("product-button-add").disabled = false
                    this.props.state.status = null
                    this.props.state.error = null
                })
            }
        }
    }

    addToCart = (e) => {
        e.target.disabled = true
        const data = {
            user: auth.currentUser?.uid || null,
            product: this.state.product.id
        }

        this.props.add_cart_item(data)
    }

    getPrice = (price, disc) => {
        if (disc !== 0) {
            return (
                <section className="product-price-container">
                    <p className="product-old-price">
                        ${price}
                    </p>
                    <p className="product-price">
                        ${price - (price * disc / 100)}
                    </p>
                </section>
            )
        } else {
            return (
                <section className="product-price-container">
                    <p className="product-price">
                        ${price}
                    </p>
                </section>
            )
        }
    }

    getRelated = () => {
        if (this.state.related.length > 0) {
            return (
                this.state.related.map((r) => {
                    return (
                        <div className="related-card-container" key={r.id}>
                            <CardRelated vol={r} />
                        </div>
                    )
                })
            )
        } else {
            return (
                <div className="related-card-container">
                    No hay productos relacionados
                </div>
            )
        }
    }

    getSideInfo = () => {
        const product = this.state.product
        if (product.type === "manga")
            return getSideInfoManga(product)
        if (product.type === "book")
            return getSideInfoLibro(product)
        if (product.type === "collection")
            return getSideInfoCol(product)
        if (product.type === "comic")
            return getSideInfoComic(product)
    }

    hasStock = () => {
        if (this.state.product.stock > 0) {
            return (
                <section className="product-info-container">
                    <h1 className="product-title">
                        {this.state.product.title}
                    </h1>
                    <p className="product-sinopsis scrollbar">
                        {this.state.product.serie.synopsis ? this.state.product.serie.synopsis : this.state.product.synopsis}
                    </p>
                    {this.getPrice(this.state.product.price, this.state.product.publisher.discount)}
                    <button className="product-buy-button" id="product-button-add" onClick={this.addToCart}>
                        Agregar al carrito
                    </button>
                </section>
            )
        } else {
            return (
                <section className="product-info-container">
                    <h1 className="product-title">
                        {this.state.product.title}
                    </h1>
                    <p className="product-sinopsis">
                        {this.state.product.serie.synopsis ? this.state.product.serie.synopsis : this.state.product.synopsis}
                    </p>
                    {this.getPrice(this.state.product.price, this.state.product.publisher.discount)}
                    <span className="product-no-stock">
                        SIN STOCK
                    </span>
                </section>
            )
        }
    }

    render() {
        if (this.state.loading) {
            return <Loader />
        } else {
            return (
                <div className="product-main-container">
                    <div className="main-container">
                        <section className="product-main-column">
                            <img className="product-img"
                                alt="img-cover"
                                src={this.state.product.img_url}
                            />
                        </section>
                        <section className="product-main-column">
                            {this.hasStock()}
                        </section>
                    </div>
                    <div className="product-side-info-container">
                        <h3 className="product-side-info-main-title">
                            Información
                        </h3>
                        {
                            this.getSideInfo()
                        }
                    </div>
                    <div className="product-related-container">
                        <h1 className="product-related-title">
                            Productos relacionados
                        </h1>
                        <section className="product-related">
                            <section className="product-related-grid">
                                {
                                    this.getRelated()
                                }
                            </section>
                        </section>
                    </div>
                </div>
            )
        }
    }

}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    add_cart_item,
    get_product
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(Product)