import React from "react";
import Loader from "../../../components/loader/loader";

import './adminStock.css'
//import Stock from "./stock";
import checkUser from "../checkUser";

import { connect } from 'react-redux'
import { getStockProducts } from '../../../redux/actions'
import StockForm from "./stockForm";
import BackToDash from "../backToDash/backToDash";

class AdminStock extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            products: {},
            loading: true,
            user: null
        }
    }

    componentDidMount() {
        this.setState({
            user: this.props.user
        }, async () => {
            checkUser(this)
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user !== this.props.user) {
            this.setState({
                user: this.props.user
            })
        }

        if (prevState.user !== this.state.user) {
            checkUser(this)
        }
    }

    render() {
        if (this.state.loading) return <Loader />
        return (
            <div className="adminstock-main">
                <BackToDash />
                <StockForm />
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    getStockProducts
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(AdminStock);