export function getGuestCart() {
    const items = window.localStorage.getItem('bookhall-cart')
    if (!items) return []

    const parsed_items = JSON.parse(items).cart
    return parsed_items
}

export function updateGuestCart(product, qty) {
    const items = window.localStorage.getItem('bookhall-cart')

    let parsed_items = JSON.parse(items)?.cart || []

    // Encuentra el índice del producto a actualizar
    const productIndex = parsed_items.findIndex(item => item.product === product)

    if (productIndex !== -1) {
            parsed_items[productIndex].qty += qty
    } else if (qty > 0) {
        // Agrega el producto si no está en el carrito y la cantidad es mayor que 0
        parsed_items.push({ product: product, qty: qty })
    }

    const items_data = { cart: parsed_items }
    const string_data = JSON.stringify(items_data)

    window.localStorage.setItem('bookhall-cart', string_data)
    return true
}

export function addGuestCartItem(product, qty) {
    let cart = []
    const items = window.localStorage.getItem('bookhall-cart')
    if (items) cart = JSON.parse(items).cart

    // Encuentra el índice del producto a agregar
    const productIndex = cart.findIndex(item => item.product === product)

    if (productIndex !== -1) {
        // Si el producto ya está en el carrito, incrementa la cantidad
        cart[productIndex].qty += qty
    } else {
        // Si el producto no está en el carrito, agrégalo
        cart.push({ product: product, qty: qty })
    }

    const items_data = { cart: cart }
    const string_data = JSON.stringify(items_data)

    window.localStorage.setItem('bookhall-cart', string_data)
    return true
}

export async function setGuestDiscount(discount){
    window.localStorage.setItem('bookhall-tmp_discount', discount)
    return true
}

export function getGuestDiscount(){
    const discount = window.localStorage.getItem('bookhall-tmp_discount')
    return discount !== 'undefined' ? discount : 'abc%%0'
}