import React from "react"
import Loader from "../../../components/loader/loader"

import { connect } from 'react-redux'
import { get_editoriales, add_publisher_discount, add_discount_code } from '../../../redux/actions'

import BackToDash from '../backToDash/backToDash'

import Swal from 'sweetalert2'
import checkUser from "../checkUser"

class Descuentos extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: null,
            editoriales: [],
            loading: true,
            type: 'publisher',
            actual_discount: 0,
            publisher_id: null
        }
    }

    componentDidMount() {
        this.props.get_editoriales()

        this.setState({
            user: this.props.user
        }, () => {
            checkUser(this)
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.user !== prevProps.user) {
            this.setState({
                user: this.props.user
            }, () => {
                checkUser(this)
            })
        }

        if (prevProps.state.editoriales !== this.props.state.editoriales) {
            const editoriales = this.props.state.editoriales
            editoriales.sort((a, b) => a.name.localeCompare(b.name))
            this.setState({
                editoriales: editoriales
            })
        }

        if (prevProps.state.status !== this.props.state.status && this.props.state.status) {
            Swal.fire({
                icon: "success",
                title: "Descuentos actualizados con éxito!"
            })
                .then(() => {
                    window.location.reload()
                })
        }
    }

    setPublisher = (e) => {
        const name = e.target.value
        const publisher = this.state.editoriales.find((e) => e.name === name)
        if (!publisher) return

        this.setState({
            publisher_id: publisher.id,
            actual_discount: publisher.discount
        })
    }

    setType = (e) => {
        const select = e.target.value
        const type = select === 'Descuento por editorial' ? 'publisher' : 'code'
        this.setState({
            type: type
        })
    }

    getStyle = (type) => {
        if (type === this.state.type) return { 'display': 'flex' }
        return { 'display': 'none' }
    }

    submit = (e) => {
        e.preventDefault()
        if(this.state.type === 'publisher'){
            const data = {
                publisher: this.state.publisher_id,
                discount: document.getElementById('publisher-discount').value
            }

            this.props.add_publisher_discount(data)
        }else{
            const data = {
                code: document.getElementById('discount-name').value,
                discount: document.getElementById('discount-percentage').value
            }

            this.props.add_discount_code(data)
        }
    }

    render() {
        if (this.state.loading) {
            return <Loader />
        } else {
            return (
                <div className="dashboard-main-container">
                    <BackToDash />
                    <br />
                    <form className="dash-form-container" onSubmit={this.submit}>
                        <h3 className="dash-form-title">
                            Aministrar descuentos
                        </h3>
                        <select
                            className="dash-form-input"
                            id="addmanga-state"
                            onChange={this.setType}
                        >
                            <option>
                                Descuento por editorial
                            </option>
                            <option>
                                Código de descuento
                            </option>
                        </select>
                        <span className="dash-form-column" style={this.getStyle('publisher')}>
                            <select
                                className="dash-form-input"
                                id="addmanga-editorial"
                                onChange={this.setPublisher}
                            >
                                <option key={`-addmanga`} selected={true} disabled={true}>
                                    Editorial
                                </option>
                                {
                                    this.state.editoriales.map((e) => {
                                        return (
                                            <option key={e.id}>
                                                {e.name}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                            <br />
                            <p>Descuento actual: {this.state.actual_discount}%</p>
                            <br />
                            <input
                                className="dash-form-input"
                                id="publisher-discount"
                                type="number"
                                placeholder="Descuento"
                            />
                        </span>
                        <span className="dash-form-column" style={this.getStyle('code')}>
                            <input
                                className="dash-form-input"
                                id="discount-name"
                                type="text"
                                placeholder="Código"
                            />
                            <br />
                            <input
                                className="dash-form-input"
                                id="discount-percentage"
                                type="number"
                                placeholder="Descuento"
                            />
                        </span>
                        <br />
                        <button className="dash-form-button" type="submit">
                            Confirmar
                        </button>
                    </form>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    get_editoriales,
    add_publisher_discount,
    add_discount_code
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(Descuentos);