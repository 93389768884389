export function getSideInfoManga(product) {
    console.log(product)
    return (
        <section className="product-side-info">
            <span className="product-side-info-column">
                <span className="product-side-info-row">
                    <p className="product-side-info-title">
                        Editorial
                    </p>
                    <a href={`/#/publisher/${product.publisher.id}`} className="product-side-info-text side-info-link">
                        {product.publisher.name}
                    </a>
                </span>

                <span className="product-side-info-row">
                    <p className="product-side-info-title">
                        Serie
                    </p>
                    <a href={`/#/serie/${product.serie.id}`} className="product-side-info-text side-info-link">
                        {product.serie.name}
                    </a>
                </span>

                <span className="product-side-info-row">
                    <p className="product-side-info-title">
                        Estado
                    </p>
                    <p className="product-side-info-text">
                        {product.serie.state}
                    </p>
                </span>
            </span>
            <span className="product-side-info-column">
                <span className="product-side-info-row">
                    <p className="product-side-info-title">
                        Guión
                    </p>
                    <p className="product-side-info-text">
                        {product.authors.writer}
                    </p>
                </span>

                <span className="product-side-info-row">
                    <p className="product-side-info-title">
                        Dibujo
                    </p>
                    <p className="product-side-info-text">
                        {product.authors.drawer}
                    </p>
                </span>
            </span>
        </section>
    )
}

export function getSideInfoComic(product) {
    return (
        <section className="product-side-info">
            <span className="product-side-info-column">
                <span className="product-side-info-row">
                    <p className="product-side-info-title">
                        Editorial
                    </p>
                    <a href={`/#/publisher/${product.publisher.id}`} className="product-side-info-text side-info-link">
                        {product.publisher.name}
                    </a>
                </span>
                <span className="product-side-info-row">
                    <p className="product-side-info-title">
                        Serie
                    </p>
                    <a href={`/#/serie/${product.serie.id}`} className="product-side-info-text side-info-link">
                        {product.serie.name}
                    </a>
                </span>
                <span className="product-side-info-row">
                    <p className="product-side-info-title">
                        Estado
                    </p>
                    <p className="product-side-info-text">
                        {product.serie.state}
                    </p>
                </span>
            </span>
            <span className="product-side-info-column">
                <span className="product-side-info-row">
                    <p className="product-side-info-title">
                        Guión
                    </p>
                    <p className="product-side-info-text">
                        {product.authors.writer}
                    </p>
                </span>

                <span className="product-side-info-row">
                    <p className="product-side-info-title">
                        Dibujo
                    </p>
                    <p className="product-side-info-text">
                        {product.authors.drawer}
                    </p>
                </span>
                <span className="product-side-info-row">
                    <p className="product-side-info-title">
                        Colorista
                    </p>
                    <p className="product-side-info-text">
                        {product.authors.colourist}
                    </p>
                </span>
                <span className="product-side-info-row">
                    <p className="product-side-info-title">
                        Entintado
                    </p>
                    <p className="product-side-info-text">
                        {product.authors.inker}
                    </p>
                </span>
            </span>
        </section>
    )
}

export function getSideInfoCol(product) {
    console.log(product)
    return (
        <section className="product-side-info">
            <span className="product-side-info-column">
                <span className="product-side-info-row">
                    <p className="product-side-info-title">
                        Editorial
                    </p>
                    <a href={`/#/publisher/${product.publisher.id}`} className="product-side-info-text side-info-link">
                        {product.publisher.name}
                    </a>
                </span>
                <span className="product-side-info-row">
                    <p className="product-side-info-title">
                        Colección
                    </p>
                    <a href={`/#/serie/${product.serie.id}`} className="product-side-info-text side-info-link">
                        {product.serie.name}
                    </a>
                </span>
            </span>
            <span className="product-side-info-column">
                <span className="product-side-info-row">
                    <p className="product-side-info-title">
                        Guión
                    </p>
                    <p className="product-side-info-text">
                        {product.authors.writer}
                    </p>
                </span>

                <span className="product-side-info-row">
                    <p className="product-side-info-title">
                        Dibujo
                    </p>
                    <p className="product-side-info-text">
                        {product.authors.drawer}
                    </p>
                </span>
                <span className="product-side-info-row">
                    <p className="product-side-info-title">
                        Colorista
                    </p>
                    <p className="product-side-info-text">
                        {product.authors.colourist}
                    </p>
                </span>
                <span className="product-side-info-row">
                    <p className="product-side-info-title">
                        Entintado
                    </p>
                    <p className="product-side-info-text">
                        {product.authors.inker}
                    </p>
                </span>
            </span>
        </section>
    )
}

export function getSideInfoLibro(product) {
    return (
        <section className="product-side-info">
            <span className="product-side-info-column">
                <span className="product-side-info-row">
                    <p className="product-side-info-title">
                        Editorial
                    </p>
                    <a href={`/#/publisher/${product.publisher.id}`} className="product-side-info-text side-info-link">
                        {product.publisher.name}
                    </a>
                </span>

                <span className="product-side-info-row">
                    <p className="product-side-info-title">
                        Serie
                    </p>
                    <a href={`/#/serie/${product.serie.id}`} className="product-side-info-text side-info-link">
                        {product.serie.name}
                    </a>
                </span>

                <span className="product-side-info-row">
                    <p className="product-side-info-title">
                        Autor
                    </p>
                    <p className="product-side-info-text">
                        {product.authors.writer}
                    </p>
                </span>
            </span>
        </section>
    )
}