import React from "react";

import { Link } from 'react-router-dom'

import { BsBookmarkStarFill, BsCartFill } from 'react-icons/bs'

import { auth } from '../../firebase'
import { connect } from 'react-redux'
import { add_cart_item, addToFavs } from "../../redux/actions/index";

import Swal from "sweetalert2";

import './card.css'

class Card extends React.Component {

    componentDidUpdate(prevProps) {
        if (prevProps.state.status !== this.props.state.status) {
            if (this.props.state.status) {
                Swal.fire({
                    icon: 'success',
                    title: "Agregado exitosamente!"
                }).then(() => {
                    window.location.reload()
                })
            } else if (this.props.state.error) {
                Swal.fire({
                    icon: 'error',
                    title: this.props.state.error
                }).then(() => {
                    document.getElementById("product-button-add").disabled = false
                    this.props.state.status = null
                    this.props.state.error = null
                })
            }
        }
    }

    addToCart = (e) => {
        e.preventDefault()
        const data = {
            user: auth.currentUser?.uid || null,
            product: this.props.vol.id
        }

        this.props.add_cart_item(data)
    }

    addToFavs = (e) => {
        e.preventDefault()
        const data = {
            cant: 0,
            new_cant: 1,
            id: this.props.vol.id,
            user: auth.currentUser?.uid || null,
            stock: this.props.vol.stock
        }

        this.props.addToFavs(data)
    }

    hasStock = () => {
        if (this.props.vol.stock > 0) {
            return (
                <span className="card-actions">
                    <button className="action-button" onClick={this.addToCart}>
                        <BsCartFill className="action-icon" id="product-button-add" />
                    </button>
                </span>
            )
        } else {
            return (
                <span className="card-actions no-stock">
                    <p className="sin-stock-p">
                        Sin stock
                    </p>
                </span>
            )
        }
    }

    getPrice = () => {
        const price = this.props.vol.price
        const descuento = this.props.vol.publisher.discount
        if (descuento > 0) {
            const old_price = price
            const new_price = price - (price * descuento / 100)

            return (
                <span className="card-price">
                    <p className="card-price-p-old">
                        ${old_price}
                    </p>
                    <p className="card-price-p">
                        ${new_price}
                    </p>
                </span>
            )

        } else {
            return (
                <span className="card-price">
                    <p className="card-price-p">
                        ${this.props.vol.price}
                    </p>
                </span>
            )
        }
    }


    render() {
        return (
            <Link to={`/product/${this.props.vol.id}`} className="card-main-container">
                <img className="card-img" alt="cover" src={this.props.vol.img_url} />
                {this.getPrice()}
                <section className="card-info">
                    <p className="card-title">
                        {this.props.vol.title}
                    </p>
                    {this.hasStock()}
                </section>
            </Link>
        )
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    add_cart_item,
    addToFavs
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(Card);