import handleErrors from "./handleErrors";

export default function createMPCard(total, submit, propsSubmit){
    const cardForm = window.mp.cardForm({
        amount: `${total}`,
        autoMount: true,
        form: {
            id: "form-checkout",
            cardholderName: {
                id: "form-checkout__cardholderName",
                placeholder: "Titular de la tarjeta",
            },
            cardholderEmail: {
                id: "form-checkout__cardholderEmail",
                placeholder: "E-mail",
            },
            cardNumber: {
                id: "form-checkout__cardNumber",
                placeholder: "Número de la tarjeta",
            },
            cardExpirationDate: {
                id: "form-checkout__cardExpirationDate",
                placeholder: "Fecha de vencimiento (MM/YYYY)",
            },
            securityCode: {
                id: "form-checkout__securityCode",
                placeholder: "Código de seguridad",
            },
            installments: {
                id: "form-checkout__installments",
                placeholder: "Cuotas",
            },
            identificationType: {
                id: "form-checkout__identificationType",
                placeholder: "Tipo de documento",
            },
            identificationNumber: {
                id: "form-checkout__identificationNumber",
                placeholder: "Número de documento",
            },
            issuer: {
                id: "form-checkout__issuer",
                placeholder: "Banco emisor",
            },
        },
        callbacks: {
            onFormMounted: error => {
                if (error) {
                    window.location.reload()
                }
            },
            onSubmit: event => {
                event.preventDefault()
                const {
                    paymentMethodId: payment_method_id,
                    issuerId: issuer_id,
                    cardholderEmail: email,
                    amount,
                    token,
                    identificationNumber,
                    identificationType,
                } = cardForm.getCardFormData()

                const installments = document.getElementById("form-checkout__installments").value
                const purchase = submit()

                const body = {
                    token,
                    issuer_id,
                    payment_method_id,
                    transaction_amount: Number(amount),
                    installments: Number(installments),
                    description: "Compra The Bookhall L&C",
                    payer: {
                        email,
                        identification: {
                            type: identificationType,
                            number: identificationNumber,
                        },
                    },
                    user: "anónimo",
                    purchase: purchase
                }

                return propsSubmit(body)

            },
            //ERRORES
            onPaymentMethodsReceived: (error, paymentMethods) => {
                if (error) {
                    return handleErrors(error);
                }
            },
            onIssuersReceived: (error, issuers) => {
                if (error) {
                    return handleErrors(error);
                }
            },
            onInstallmentsReceived: (error, installments) => {
                if (error) {
                    return handleErrors(error);
                }
            },
            onCardTokenReceived: (error, token) => {
                if (error) {
                    return handleErrors(error);
                }
            },

        },
    })
}