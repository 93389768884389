import React from "react";

import { connect } from 'react-redux'
import { getUser } from "./redux/actions";

import Loader from './components/loader/loader'

import "./mainComponent.css"

import NavBar from "./components/navBar/navBar";
import PageComponent from "./pages/PageComponent";
import Footer from "./components/footer/footer";

import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import Building from "./components/pageBuilding/pageBuilding";

class MainComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: undefined,
            loading: false,
            call_user: false
        }
    }

    componentDidMount(){
        onAuthStateChanged(auth, (user) => {
            if(user){
                this.setState({
                    call_user: true
                })
            }else{
                this.setState({
                    call_user: false
                })
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {

        if(prevState.call_user !== this.state.call_user){
            if(this.state.call_user){
                this.props.getUser(auth.currentUser.uid)
            }else{
                this.setState({
                    user: false
                })
            }
        }

        if (prevProps.state.user !== this.props.state.user) {
            this.setState({
                user: this.props.state.user
            }, () => {
                this.setState({
                    loading: false
                })
            })
        }
    }

    render() {
        if(!(auth.currentUser?.uid === 'r3XUxJFTvvb1Xe5VUNfNlJ5zHNu2' || auth.currentUser?.uid === 'DbX8EkfUIme6Nxj52Ix4vMuHELv2')){
            return(
                <div className="mainComponent scrollbar">
                    <NavBar user={this.state.user} />
                    <Building />
                    <Footer />
                </div>
            )
        }

        if (this.state.loading) {
            return <Loader />
        } else {
            return (
                <div className="mainComponent scrollbar">
                    <NavBar user={this.state.user} />
                    <PageComponent loc={this.props.loc} user={this.state.user} />
                    <Footer />
                </div>
            )
        }
    }

}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    getUser
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(MainComponent);