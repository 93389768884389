import React from "react"
import Swal from 'sweetalert2'

import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { add_cart_item } from '../../../redux/actions'
import { auth } from '../../../firebase'
import { IoCartSharp } from 'react-icons/io5'

import './news.css'

class News extends React.Component {
    vol
    constructor(props) {
        super(props)
        this.vol = this.props.vol
    }

    componentDidUpdate(prevProps) {
        if (prevProps.state.status !== this.props.state.status) {
            if (this.props.state.status) {
                Swal.fire({
                    icon: "success",
                    title: `Agregado correctamente!`
                })
                    .then(() => {
                        return window.location.reload()
                    })
            }
        }
    }

    getPrice = () => {
        if (this.vol.publisher.discount) {
            return (
                <span className="news-price-main desc">
                    <span className="news-price-container">
                        <p className="news-old-price">${this.vol.price}</p>
                        <p className="news-price">
                            ${parseInt(this.vol.price) - (parseInt(this.vol.price) * parseInt(this.vol.publisher.discount) / 100)}
                        </p>
                    </span>
                    <IoCartSharp className="news-cart-icon" onClick={this.addToCart} />
                </span>
            )
        } else {
            return (
                <span className="news-price-main desc">
                    <span className="news-price-container">
                        <p className="news-price">
                            ${parseInt(this.vol.price)}
                        </p>
                    </span>
                    <IoCartSharp className="news-cart-icon" onClick={this.addToCart} />
                </span>
            )
        }
    }

    addToCart = (e) => {

        e.target.disabled = true

        const data = {
            user: auth.currentUser?.uid || null,
            product: this.state.vol.id
        }

        this.props.add_cart_item(data)
    }

    render() {
        if (!this.vol) {
            return null
        } else {
            return (
                <div className="news-main">
                    <Link to={"product/" + this.vol.id} className="news-img">
                        <img src={this.vol.img_url} className="news-img" alt={"Novedades"} />
                    </Link>
                    <span className="news-info">
                        <span className="news-card-title-container">
                            <p className="news-card-title">
                                {this.vol.title}
                            </p>
                        </span>
                        {this.getPrice()}
                    </span>
                </div>
            )
        }
    }

}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    add_cart_item
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(News);