export default function filterProducts(allProducts = [], filter = '') {
    let newProducts = [...allProducts]
    if (filter === '') {
        return allProducts
    }

    const filtered = newProducts.sort((a, b) => {
        switch (filter) {
            case 'mayor':
                return parseInt(b.product.precio) - parseInt(a.product.precio)
            case 'menor':
                return parseInt(a.product.precio) - parseInt(b.product.precio)
            case 'stock':
                if (parseInt(a.product.stock) > 0)
                    return -1
                if (parseInt(b.product.stock) > 0)
                    return 1
                return 0
            default:
                return 0
        }
    })

    return filtered

}

function sorted(arreglo = []){
    const nuevoArreglo = arreglo.sort((a, b)=>{
        return a - b
    })

    return nuevoArreglo
}