import React from "react";

import './card.css'

class Card extends React.Component {
    //rgb(55, 130, 175)

    getPrice = () => {
        if (this.props.vol.publisher.discount) {
            return (
                <div className="cart-card-price-container">
                    <p className="cart-card-price cart-card-old">
                        ${this.props.vol.price}
                    </p>
                    <p className="cart-card-price">
                        ${parseInt(this.props.vol.price) - (parseInt(this.props.vol.price) * parseInt(this.props.vol.publisher.discount) / 100)}
                    </p>
                </div>
            )
        } else {
            return (
                <div className="cart-card-price-container">
                    <p className="cart-card-price">
                        ${parseInt(this.props.vol.price)}
                    </p>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="cart-card-main">
                <img className="card-cart-img" src={this.props.vol.img_url} alt="cover" />
                <div className="card-cart-info">
                    <span className="cart-card-title">
                        {this.props.vol.title}
                    </span>
                    {this.getPrice()}
                </div>
            </div>
        )
    }

}

export default Card